import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from 'shared/components/Button';
import ErrorDialog from 'shared/components/ErrorDialog';
import { fontSizes } from 'styles/vars';
import { colors } from 'styles/colors';
import IconButton from 'shared/components/IconButton';
import { getAppEnvironments, getTenant } from 'shared/sharedActions';
import { sortAlphabeticallyByField } from 'lib/util';
import { postCommand } from 'shared/commandActions';

function EnabledAppEnvDialog(props) {
  const dispatch = useDispatch();
  const { open, onClose, tenant } = props;
  const [saving, setSaving] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });
  const appEnvironments = useSelector(x => x.shared.appEnvironments);
  const currentTenant = useSelector(x => x.shared.currentTenant);

  useEffect(() => {
    dispatch(getTenant(tenant.id));
    dispatch(getAppEnvironments());
  }, [dispatch, tenant]);

  useEffect(() => {
    setSaving(false);
  }, [currentTenant])

  const handleAddClicked = async (appEnv) => {
    setSaving(true);
    const cmd = {
      type: 'ADD_TENANT_APP_ENV_ID',
      payload:
      {
        tenantId: tenant.id,
        appEnvId: appEnv.id
      }
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setErrorDialogState({ visible: true, error: result.error, errorDescription: result.errorDescription });
    } else {
      dispatch(getTenant(tenant.id));
    }
  };

  const handleRemoveClick = async (appEnv) => {
    setSaving(true);
    const cmd = { type: 'REMOVE_TENANT_APP_ENV_ID', payload:
      {
        tenantId: tenant.id,
        appEnvId: appEnv.id,
      }
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setErrorDialogState({ visible: true, error: result.error, errorDescription: result.errorDescription });
    } else {
      dispatch(getTenant(tenant.id));
    }
  };

  const handleClose = () => {
    setErrorDialogState({ ...errorDialogState, visible: false });
  };

  if (!appEnvironments || !currentTenant) {
    return <div></div>;
  }

  const enabledAppEnvs = appEnvironments && appEnvironments.filter(x => currentTenant.enabledAppEnvIds.indexOf(x.id) !== -1);
  const sortedEnabledAppEnvs = enabledAppEnvs && sortAlphabeticallyByField(enabledAppEnvs, 'name');
  const anyEnabled = sortedEnabledAppEnvs && sortedEnabledAppEnvs.length > 0;
  const notEnabledAppEnvs = appEnvironments && appEnvironments.filter(x => currentTenant.enabledAppEnvIds.indexOf(x.id) === -1);
  const sortedNotEnabledAppEnvs = notEnabledAppEnvs && sortAlphabeticallyByField(notEnabledAppEnvs, 'name');

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '25px', width: '500px' }}>
        <div style={{ fontSize: fontSizes.l, marginBottom: '10px' }}>
          App Environments
        </div>
        <div style={{ fontSize: fontSizes.r, fontStyle:'italic', marginBottom: '10px' }}>
          These are the environments that this tenant will be available in.  For example, if you enable 'Labor Demo', this tenant will show up in the tenants list of the 'Labor Demo' environment.
        </div>

        <div className="row" style={{ padding: '10px 10px 10px 0px', fontSize: fontSizes.m }}>
          Enabled Environments
        </div>

        <div style={{ fontSize: fontSizes.r, marginBottom: '20px' }}>
          <div className="row" style={{ padding: '5px', display: 'flex', flexDirection: 'column', border: '0.5px solid lightgrey', maxHeight: '150px', overflowY: 'auto' }}>
            {
              !anyEnabled && <div>No enabled environments</div>
            }
            {
              sortedEnabledAppEnvs && sortedEnabledAppEnvs.map(a => (
                <div key={a.id} style={{ padding: '10px', display: 'grid',  gridAutoFlow: 'column', gridColumnGap: '10px', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <IconButton icon="times" color={colors.red}
                    onClick={() => handleRemoveClick(a)} disabled={saving} />
                  <div>{a.name}</div>
                </div>
              ))
            }
          </div>
        </div>

        <div className="row" style={{ padding: '10px 10px 10px 0px', fontSize: fontSizes.m }}>
          Available Environments
        </div>

        <div style={{ fontSize: fontSizes.r }}>
          <div className="row" style={{ padding: '5px', display: 'flex', flexDirection: 'column', maxHeight: '250px', overflowY: 'auto', border: '0.5px solid lightgrey' }}>
            {
              sortedNotEnabledAppEnvs && sortedNotEnabledAppEnvs.map(a => (
                <div key={a.id} style={{ padding: '10px', display: 'grid',  gridAutoFlow: 'column', gridColumnGap: '10px', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <IconButton icon="plus-square" onClick={() => handleAddClicked(a)}
                      disabled={saving} />
                  <div>{a.name}</div>
                </div>
              ))
            }
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium', marginTop: '10px' }}>
          <Button primary style={{ padding: '5px', margin: '5px' }} onClick={onClose}>OK</Button>
        </div>
      </div>
      {errorDialogState.visible &&
        <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={handleClose} />}
    </Dialog>
  );
}

export default EnabledAppEnvDialog;