export const postCommand = async (cmd) => {
  const bodyText = JSON.stringify(cmd);
  const result = await fetch(`/api/commands`, {
    method: 'POST',
    body: bodyText,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
  if (result.status >= 300) {
    try {
        const content = await result.json();
        return { error: content.error, errorDescription: content.errorDescription };
    }
    catch(e) {
      return { error: 'bad_http_status', errorDescription:'Unexpected status code result: ' + result.status};
    }
  }

  return {};
};

export const postPublicCommand = async (cmd) => {
  const bodyText = JSON.stringify(cmd);
  const result = await fetch(`/api/publiccommands`, {
    method: 'POST',
    body: bodyText,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
  if (result.status >= 300) {
    try {
      console.log(Object.keys(result));
      console.log(await result.reason);
        const content = await result.json();
        return { error: content.error, errorDescription: content.errorDescription };
    }
    catch(e) {
      console.log(e);
      return { error: 'bad_http_status', errorDescription:'Unexpected status code result: ' + result.status};
    }
  }

  return {};
};