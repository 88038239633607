import React from 'react';
import styled from 'styled-components';
import HomeIcon from '@material-ui/icons/HomeOutlined';

function HomeButton() {
  const homeUrl = window.env.homeUrl;
  return (
    <HomeButtonContainer>
      <a href={homeUrl} title="Home">
        <HomeIconStyled />
      </a>
    </HomeButtonContainer>
  );
}

export default HomeButton;

const HomeButtonContainer = styled.div`
`;

const HomeIconStyled = styled(HomeIcon)`
  color: var(--color-theme-primary);
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    color: var(--color-theme-primary-d);
  }
`;