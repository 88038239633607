import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from 'shared/components/Button';
import IconButton from 'shared/components/IconButton';
import Checkbox from 'shared/components/Checkbox';
import { postCommand } from 'shared/commandActions';
import { fontSizes } from 'styles/vars'
import { uuid } from 'lib/util';

function ResetPasswordDialog(props) {
  const { open, onClose, userId } = props;
  const [tempPassword] = useState(uuid().substring(0, 8));
  const [sendEmail, setSendEmail] = useState(false);

  const handleResetClicked = async () => {
    const cmd = {
      type: 'RESET_PASSWORD',
      payload: {
        userId: userId,
        newPassword: tempPassword,
        sendEmail: sendEmail,
      },
    };
    const result = await postCommand(cmd);
    if(result.error) {
      console.log(result.error);
    } else {
      onClose();
    }
  };

  const handleCancelClicked = () => {
    onClose();
  };

  const handleCopyTempPassword = () => {
    navigator.clipboard.writeText(tempPassword);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px', width:'500px' }}>
        <div style={{ padding: '10px', fontSize: fontSizes.l}}>Reset Password</div>

        <div className="row" style={{ padding: '30px', display: 'flex', alignItems: 'center', paddingBottom:'0px' }}>
          <div className="col-6">Temporary Password:</div>
          <div style={{display:'flex', alignItems:'middle'}}>
            <div style={{display:'flex', alignItems:'center', marginRight:'5px'}}>{tempPassword}</div>
            <div style={{padding:'3px'}}>
              <IconButton icon="copy" onClick={handleCopyTempPassword} />
            </div>
          </div>
        </div>

        <div className="row" style={{ padding: '30px', display: 'flex', alignItems: 'center', paddingTop:'0px' }}>
          <div className="col-6">Send user e-mail with temporary password:</div>
          <div style={{display:'flex', alignItems:'middle'}}>
            <div style={{padding:'3px'}}>
              <Checkbox checked={sendEmail} onChange={x => setSendEmail(!sendEmail)} />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button primary onClick={handleResetClicked}>Reset</Button>
          <Button  onClick={handleCancelClicked}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ResetPasswordDialog;