import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { getTenants } from './tenantsListActions.js';
import * as routeBuilder from '../../../lib/routeBuilder';
import SearchBox from 'shared/components/SearchBox';
import AddTenantDiialog from './AddTenantDialog.js';
import { fontSizes } from 'styles/vars'
import { sortAlphabeticallyByField } from '../../../lib/util';
import IconLinkButton from 'shared/components/IconLinkButton.js';
import { arrayContains } from 'lib/util';
import { tenantAdminRoles } from '../../../constants.js';
import TenantImage from 'shared/components/tenantImage/TenantImage';
import Spinner from 'shared/components/spinner';

function TenantsList() {
  const dispatch = useDispatch();
  const [filterString, setFilterString] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const tenants = useSelector(rs => rs.tenantsList.tenants);
  const currentUserProfile = useSelector(rs => rs.currentUser.profile);

  useEffect(() => {
    dispatch(getTenants());
  }, [dispatch]);

  if (tenants == null) {
    return <Spinner />;
  }

  if (tenants.length === 0) {
    window.location.replace('/app/userlanding');
    return <Spinner />;
  }

  if (tenants.length === 1) {
    window.location.replace(routeBuilder.buildTenantDetail(tenants[0].id));
    return <Spinner />;
  }

  const handleAddDialogClose = () => {
    setShowAddDialog(false);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setFilterString(value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const orderedTenants = sortAlphabeticallyByField(tenants, 'name');
  const showAddButton = arrayContains(tenantAdminRoles, currentUserProfile.role);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '600px', margin: '0 auto' }}>
      <div style={{ padding: '15px', display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: fontSizes.xl, fontWeight: '300', marginRight: '30px', marginBottom: '5px' }}>
          Tenants
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            showAddButton && (
              <div style={{ padding: '3px 8px 3px 3px' }}>
                <IconLinkButton icon="plus" text="Add" onClick={() => setShowAddDialog(true)} />
              </div>
            )
          }
          <div style={{ padding: '3px' }}>
            <SearchBox onChange={debouncedHandleSearchChange} />
          </div>
        </div>

      </div>
      <div className="table" style={{ fontSize: fontSizes.r, minWidth: '420px' }}>
        {
          orderedTenants.filter(x => x.name.toLowerCase().indexOf(filterString.toLowerCase()) !== -1).map(tenant => {
            return (
              <div className="table-row" key={tenant.id} style={{ height: '60px', padding: '0px' }}>
                <Link className="hover-themed" to={routeBuilder.buildTenantDetail(tenant.id)}>
                  <div className="col-12"
                    style={{
                      height: '100%', display: 'grid', alignItems: 'center', gridAutoFlow: 'column',
                      justifyContent: 'flex-start', gridColumnGap: '10px', cursor: 'pointer'
                    }}>
                    <div style={{ width: '40px', cursor: 'pointer' }}>
                      <TenantImage tenantId={tenant.id} width='40px' />
                    </div>
                    {tenant.name}
                  </div>
                </Link>
              </div>
            );
          })
        }
      </div>
      {showAddDialog && <AddTenantDiialog open={showAddDialog} onClose={handleAddDialogClose} />}
    </div>
  )
}

export default TenantsList;