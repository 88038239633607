import React from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './sysAdminMenu.css';

function SysAdminMenu() {
  return (
    <>
    <div className="dropdown" style={{cursor:'pointer'}}>
      <div style={{display: 'flex'}}>
        <div style={{padding: '5px', fontSize:'small'}}>
          Admin
        </div>
        <div style={{padding: '5px', fontSize:'small'}}>
          <FontAwesomeIcon icon="chevron-down"/>
        </div>
      </div>
      <div className="dropdown-content">
          <Link to="/app/appinfo">
            <div>
              App Info
            </div>
          </Link>
          <Link to="/app/tracelog">
            <div>
              Trace Log
            </div>
          </Link>
          <Link to="/app/securityeventlog">
            <div>
              Security Log
            </div>
          </Link>
      </div>
    </div>
    </>
  );
}

export default SysAdminMenu;