import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from './Button';

function ConfirmDialog(props) {
  const { open, onClose, confirmState, onConfirm } = props;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px' }}>
        <div style={{ padding: '10px', fontSize: 'large', marginRight: '200px' }}>{confirmState.title}</div>
        <div style={{ display: 'flex', fontSize: 'small', padding: '5px', marginBottom:'10px' }}>
          <div style={{ padding: '5px' }}>{confirmState.message}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium' }}>
          <Button danger style={{padding: '5px', margin: '5px'}} onClick={handleConfirm}>Confirm</Button>
          <Button style={{padding: '5px', margin: '5px'}} onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmDialog;