import { homeRedirect } from 'shared/redirects';
import { errorOccurred } from 'shared/sharedActions';

export const getTenants = () => async dispatch => {
    const result = await fetch(`/api/tenants?adminOnly=true`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Tenants', 'Unexpected status code: ' + result.status));
        return;
    }
    let tenants = [];
    try {
        tenants = await result.json();
        dispatch({ type: 'GET_TENANTS_SUCCESS', payload: { tenants } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Tenants', 'Unable to read response'));
    }
};