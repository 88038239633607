import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from 'shared/components/Button';
import ErrorDialog from 'shared/components/ErrorDialog';
import { fontSizes } from 'styles/vars';
import { colors } from 'styles/colors';
import { getGroups, getGroupsForMember } from 'components/groupsList/groupsListActions';
import IconButton from 'shared/components/IconButton';
import { sortAlphabeticallyByField } from 'lib/util';
import * as constants from 'constants.js';
import { postCommand } from 'shared/commandActions';

function GroupMembershipDialog(props) {
  const dispatch = useDispatch();
  const { open, onClose, tenant, user } = props;
  const [saving, setSaving] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });
  const userProfile = useSelector(x => x.currentUser.profile);
  const currentMemberships = useSelector(x => x.groupsList.selectedMemberGroupMemberships);
  const selectedMemberId = useSelector(x => x.groupsList.selectedMemberId);
  const groups = useSelector(x => x.groupsList.groups);
  const memberId = !!user ? user.id : tenant.id;
  const membershipType = !!user ? constants.groupMembershipTypes.User : constants.groupMembershipTypes.Tenant;

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getGroupsForMember(memberId));
  }, [dispatch, user, tenant, memberId]);

  useEffect(() => {
    setSaving(false);
  }, [currentMemberships])

  const handleAddToGroupClicked = async (group) => {
    setSaving(true);
    const cmd = {
      type: 'CREATE_GROUP_MEMBERSHIP',
      payload:
      {
        groupMembershipInfo: {
          type: membershipType,
          groupId: group.id,
          memberId: memberId,
        },
      }
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setErrorDialogState({ visible: true, error: result.error, errorDescription: result.errorDescription });
    } else {
      dispatch(getGroupsForMember(memberId));
    }
  };

  const handleRemoveGroupClick = async (gm) => {
    setSaving(true);
    const cmd = { type: 'DELETE_GROUP_MEMBERSHIP', payload: { id: gm.id } };
    const result = await postCommand(cmd);
    if (result.error) {
      setErrorDialogState({ visible: true, error: result.error, errorDescription: result.errorDescription });
    } else {
      await dispatch(getGroupsForMember(memberId));
    }
  };

  const groupNameById = (groupId) => {
    if(!groups) {
      return '';
    }
    return groups.find(x => x.id === groupId).name;
  };

  const isMembershipAuthorized = (mg) => {
    const g = groups.find(g => g.id === mg.groupId);
    return isGroupAuthorized(g);
  };

  const isGroupAuthorized = (group) => {
    if(userProfile.role === constants.roles.SysAdmin) {
      return true;
    }
    if(group.managedByRole === constants.roles.SysAdmin) {
      return false;
    }
    return true;
  };

  const handleClose = () => {
    setErrorDialogState({ ...errorDialogState, visible: false });
  };

  const sortedGroups = groups && sortAlphabeticallyByField(groups, 'name');
  const anyCurrentMemberships = currentMemberships && currentMemberships.length > 0;
  const availableGroups = (sortedGroups && currentMemberships) && groups.filter(x => currentMemberships.filter(y => y.groupId === x.id).length === 0);
  const title = membershipType === constants.groupMembershipTypes.User ? user.name : tenant.name;

  if(!groups || selectedMemberId !== memberId) {
    return <div></div>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '25px', width: '500px' }}>
        <div style={{ fontSize: fontSizes.l, marginBottom: '10px' }}>
          Group Membership for <label style={{ fontStyle: 'italic' }}>{title}</label>
        </div>
        <div style={{ fontSize: fontSizes.r, fontStyle:'italic', marginBottom: '10px' }}>
          Groups are how you grant permissions to individual users or all users within a tenant.  Adding a group will grant all permissions associated with this group to the user(s).  For example: adding 'Prometheus Demo Users' will grant 'User' access to the 'Prometheus Demo' environment.
        </div>

        <div className="row" style={{ padding: '10px 10px 10px 0px', fontSize:fontSizes.m }}>
          Group Memberships
        </div>

        <div style={{ fontSize: fontSizes.r, marginBottom: '20px' }}>
          <div className="row" style={{ padding: '5px', display: 'flex', flexDirection:'column', border:'0.5px solid lightgrey', maxHeight: '150px', overflowY: 'auto' }}>
            {
              !anyCurrentMemberships && <div>No groups</div>
            }
            {
              anyCurrentMemberships && currentMemberships.map(mg => (
                <div key={mg.id} style={{ padding: '10px', display: 'grid', gridAutoFlow: 'column', gridColumnGap: '10px', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <IconButton icon="times" color={colors.red} 
                      onClick={() => handleRemoveGroupClick(mg)} disabled={!isMembershipAuthorized(mg) || saving}/>
                  <div>{groupNameById(mg.groupId)}</div>
                </div>
              ))
            }
          </div>
        </div>

        <div className="row" style={{ padding: '10px 10px 10px 0px', fontSize:fontSizes.m }}>
          Available Groups
        </div>

        <div style={{ fontSize: fontSizes.r }}>
          <div className="row" style={{ padding: '5px', display: 'flex', flexDirection: 'column', maxHeight: '250px', overflowY: 'auto', border:'0.5px solid lightgrey' }}>
            {
              availableGroups && availableGroups.map(g => (
                <div key={g.id} style={{ padding: '10px', display: 'grid', gridAutoFlow: 'column', gridColumnGap: '10px', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <IconButton icon="plus-square" onClick={() => handleAddToGroupClicked(g)}
                      disabled={!isGroupAuthorized(g) | saving}/>
                  <div>{g.name}</div>
                </div>
              ))
            }
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium', marginTop: '10px' }}>
          <Button primary style={{ padding: '5px', margin: '5px' }} onClick={onClose}>Close</Button>
        </div>
      </div>
      {errorDialogState.visible && 
        <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={handleClose} />}
    </Dialog>
  );
}

export default GroupMembershipDialog;