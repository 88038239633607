import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, MenuItem } from '@material-ui/core';
import Button from 'shared/components/Button';
import IconButton from 'shared/components/IconButton';
import ErrorDialog from 'shared/components/ErrorDialog';
import { postCommand } from 'shared/commandActions';
import { getTenantDetail } from 'components/tenants/tenantDetail/tenantDetailActions';
import CustomInput from 'shared/components/CustomInput';
import { fontSizes } from 'styles/vars';
import Checkbox from 'shared/components/Checkbox';
import { validateName, validateEmail, validateOptionalName } from 'lib/validators';
import {
  connectionTypes,
  availableRolesForAssignment,
  roleNamesById,
} from 'constants.js';
import { uuid } from 'lib/util';

function AddUserDialog(props) {
  const dispatch = useDispatch();
  const { open, onClose, tenant, tenantDetail } = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('User');
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });
  const connections = useSelector(rs => rs.tenantDetail.connections);
  const [tempPassword] = useState(uuid().substring(0, 8));
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [allowedConnections, setAllowedConnections] = useState(null);
  const [showTempPassword, setShowTempPassword] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [saving, setSaving] = useState(false);
  const availableRoles = availableRolesForAssignment[tenantDetail.userRoleInTenant];

  useEffect(() => {
    if (!selectedConnection) {
      const ac = connections.filter(x => tenant.allowedConnectionIds.indexOf(x.id) !== -1);
      if (ac.length === 0) {
        console.error('No allowed connections');
      }
      setAllowedConnections(ac);
      setSelectedConnection(ac[0]);
      const isUserDbConn = ac[0].connectionType === connectionTypes.UserDatabase;
      setShowTempPassword(isUserDbConn);
    }
  }, [connections, selectedConnection, allowedConnections, tenant.allowedConnectionIds]);

  const clearState = () => {
    setName('');
    setEmail('');
    setRole('User');
    setSelectedConnection(null);
    setAllowedConnections(null);
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleAddClicked = async () => {
    setSaving(true);
    const cmd = {
      type: 'CREATE_USER',
      payload: {
        userInfo: {
          name: name,
          email: email,
          role: role ? role : 'User',
          tenantId: tenant.id,
          connectionId: selectedConnection.id,
          title: title,
          phone: phone,
        },
      },
    };
    if (selectedConnection.connectionType === connectionTypes.UserDatabase) {
      cmd.payload.connectionProperties = {
        initialPassword: tempPassword,
        sendEmailWithPassword: sendEmail,
      };
    }

    const result = await postCommand(cmd);
    setSaving(false);
    if (result.error) {
      setErrorDialogState({ visible: true, title: 'Error Adding User', error: result.error, errorDescription: result.errorDescription });
    } else {
      dispatch(getTenantDetail(tenant.id));
      clearState();
      onClose();
    }
  };

  const handleCancelClicked = () => {
    clearState();
    onClose();
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleConnectionChange = (e) => {
    const selectedConnectionId = e.target.value;
    const conn = connections.find(x => x.id === selectedConnectionId);
    setSelectedConnection(conn);
    const isUserDbConn = conn.connectionType === connectionTypes.UserDatabase;
    setShowTempPassword(isUserDbConn);
  };

  const handleCopyTempPassword = () => {
    navigator.clipboard.writeText(tempPassword);
  };

  if (!selectedConnection) {
    return <></>;
  }

  const showConnections = allowedConnections && allowedConnections.length > 1;
  const nameValid = validateName(name);
  const emailValid = validateEmail(email);
  const titleValid = validateOptionalName(title);
  const phoneValid = validateOptionalName(phone);
  const allValid = nameValid && emailValid;
  const saveEnabled = allValid && !saving;

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px', width: '500px' }}>
        <div style={{ padding: '10px', fontSize: 'large', marginRight: '200px', marginBottom: '10px' }}>
          Add User
        </div>

        <div style={{ display: 'grid', gridRowGap: '10px', fontSize: fontSizes.r }}>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <CustomInput
              label="Full Name"
              error={!nameValid}
              value={name}
              onChange={handleNameChange}
              autoFocus={true}
            />
          </div>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <CustomInput
              label="E-Mail"
              type="email"
              error={!emailValid}
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <CustomInput
              label="Title"
              error={!titleValid}
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <CustomInput
              label="Phone"
              error={!phoneValid}
              value={phone}
              onChange={handlePhoneChange}
            />
          </div>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '15px', width: '100%' }}>
              <CustomInput
                label="Role"
                select={true}
                onChange={handleRoleChange}
                value={role}
              >
                {availableRoles.map(r => <MenuItem key={r} value={r}>{roleNamesById[r]}</MenuItem>)}
              </CustomInput>
              {showConnections && (
                <CustomInput
                  label="Connection"
                  select={true}
                  onChange={handleConnectionChange}
                  value={selectedConnection.id}
                >
                  {allowedConnections.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                </CustomInput>
              )}
            </div>
          </div>
          {
            showTempPassword && (
              <>
                <div style={{ display: 'grid', gridAutoFlow: 'column', gridColumnGap: '15px', width: '100%', alignItems: 'center', justifyContent: 'flex-start', padding: '15px', paddingBottom:'0px' }}>
                  <div>Temporary Password:</div>
                  <div style={{ display: 'flex', alignItems: 'middle' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>{tempPassword}</div>
                    <div style={{ padding: '3px' }}>
                      <IconButton icon="copy" onClick={handleCopyTempPassword} />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'grid', gridAutoFlow: 'column', gridColumnGap: '5px', width: '100%', alignItems: 'center', justifyContent: 'flex-start', padding: '15px', paddingTop: '0px' }}>
                  <div>Send e-mail to new user with password:</div>
                  <div style={{ display: 'flex', alignItems: 'middle' }}>
                    <div style={{ padding: '3px' }}>
                      <Checkbox checked={sendEmail} onChange={x => setSendEmail(!sendEmail)} />
                    </div>
                  </div>
                </div>
              </>
            )
          }
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium', marginTop: '10px' }}>
          <Button primary disabled={!saveEnabled} style={{ padding: '5px', margin: '5px' }} onClick={handleAddClicked}>Add</Button>
          <Button style={{ padding: '5px', margin: '5px' }} onClick={handleCancelClicked}>Cancel</Button>
        </div>
      </div>
      {errorDialogState.visible && <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={() => setErrorDialogState({ ...errorDialogState, visible: false })} />}
    </Dialog>
  );
}

export default AddUserDialog;