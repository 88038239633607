import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { getTenantDetail } from '../tenantDetailActions';
import { fontSizes } from 'styles/vars';
import { colors } from 'styles/colors';
import SearchBox from 'shared/components/SearchBox';
import { postCommand } from 'shared/commandActions';
import AddGuestDialog from './AddGuestDialog';
import IconLinkButton from 'shared/components/IconLinkButton';
import IconButton from 'shared/components/IconButton';
import ConfirmDialog from 'shared/components/ConfirmDialog';
import AlertDialog from 'shared/components/AlertDialog';
import { sortAlphabeticallyByField } from 'lib/util';
import {
  roleNamesById
} from 'constants.js';

function GuestsTable() {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState('');
  const [showAddGuest, setShowAddGuest] = useState(false);
  const [confirmState, setConfirmState] = useState({ visible: false, title: '', message: '' });
  const [alertState, setAlertState] = useState({ visible: false, title: '', message: '' });
  const tenants = useSelector(x => x.tenantsList.tenants);
  const currentUser = useSelector(x => x.currentUser.profile);
  const prms = useParams();
  const tenantId = prms.tenantId;
  const tenantDetail = useSelector(x => x.tenantDetail.detail);
  const guests = tenantDetail && tenantDetail.guests;

  if (!tenants || !guests) {
    return <div></div>
  }
  const tenant = tenants.find(x => x.id === tenantId);

  const handleSearchBoxChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchBoxChange, 300);

  const userInFilter = (user) => {
    if (user.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
      return true;
    }
    if (user.email.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
      return true;
    }
    if (user.role.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  };

  const handleDeleteGuest = (guest) => {
    if (guest.userId === currentUser.userId) {
      setAlertState({
        visible: true,
        title: 'Unauthorized',
        message: 'You cannot delete yourself.',
      });
      return;
    }

    const handleDeleteConfirmed = async (guest) => {
      const cmd = { type: 'DELETE_GUEST', payload: { id: guest.id } };
      const result = await postCommand(cmd);
      if (result.error) {
        console.error(result);
      } else {
        dispatch(getTenantDetail(tenantId));
      }
    };
    setConfirmState({
      visible: true,
      title: 'Confirm Delete Guest',
      message: 'Are you sure you want to delete this guest?',
      onConfirm: () => handleDeleteConfirmed(guest),
    });
  };

  const guestUsers = guests && guests.filter(g => userInFilter(g.user));
  const sortedGuestUsers = guestUsers && sortAlphabeticallyByField(guestUsers, 'userName');
  const anyGuestUsers = sortedGuestUsers && sortedGuestUsers.length > 0;
  const readonly = tenantDetail.readonly;

  return (
    <div style={{marginTop:'60px'}}>
      <div style={{ display: 'flex', alignItems:'center' }}>
        <div className="row">
          <div style={{ fontSize: fontSizes.m, padding: '15px', marginBottom:'5px' }}>Guests</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px', color: colors.green }}>
          { !readonly && <IconLinkButton text="Add" icon="plus" onClick={() => setShowAddGuest(true)} /> }
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchBox onChange={debouncedHandleSearchChange} />
        </div>
      </div>

      {
        tenantDetail && (
          <div className="table zebra" style={{ fontSize: 'smaller' }}>
            <div className="table-row" style={{ fontWeight: 'bold' }}>
              <div className="col-2">Name</div>
              <div className="col-3">E-mail</div>
              <div className="col-4">Role</div>
              <div className="col-3">Actions</div>
            </div>
            {
              !anyGuestUsers && (
                <div className="table-row">
                  <div className="col-12" style={{ height: '50px', display: 'flex', alignItems: 'center' }}>No guests</div>
                </div>
              )
            }
            {
              sortedGuestUsers.map(gu => {
                const name = gu.user.name === '__no_name__' ? '(No name)' : gu.user.name;

                return (
                  <div className="table-row" style={{ height: '50px' }} key={gu.guest.id}>
                    <div className="col-2" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>{name}</div>
                    <div className="col-3" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>{gu.user.email}</div>
                    <div className="col-4" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>{roleNamesById[gu.guest.role]}</div>
                    <div className="col-3" style={{ height: '100%', display: 'flex', alignItems: 'center', padding: '8px', fontSize: fontSizes.m }}>
                      <div style={{ marginRight: '8px' }} title="Delete the user">
                        { !readonly && <IconButton icon="trash" size="sm" onClick={() => handleDeleteGuest(gu.guest)} /> }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      }
      {showAddGuest && <AddGuestDialog tenant={tenant} open={showAddGuest} guests={guests} onClose={() => setShowAddGuest(false)} />}
      {confirmState.visible && <ConfirmDialog confirmState={confirmState} open={confirmState.visible} onConfirm={confirmState.onConfirm} onClose={() => setConfirmState({ ...confirmState, visible: false })} />}
      {alertState.visible && <AlertDialog alertState={alertState} open={alertState.visible} onClose={() => setAlertState({ ...alertState, visible: false })} />}
    </div>

  );
}

export default GuestsTable;