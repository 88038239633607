import { homeRedirect } from 'shared/redirects';
import { errorOccurred } from 'shared/sharedActions';

export const getTenantUsers = (tenantId) => async dispatch => {
    const result = await fetch(`/api/users?tenantId=${tenantId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Tenant Users', 'Unexpected status code: ' + result.status));
        return;
    }
    let users = [];
    try {
        users = await result.json();
        dispatch({ type: 'GET_TENANT_USERS_SUCCESS', payload: { users } });
    }
    catch(e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Tenant Users', 'Unable to read response'));
    }
};

export const getTenantConnections = (tenantId) => async dispatch => {
    const result = await fetch(`/api/connections?tenantId=${tenantId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Tenant Connections', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
        dispatch({ type: 'GET_TENANT_CONNECTIONS_SUCCESS', payload: { connections: items } });
    }
    catch(e) {
        dispatch(errorOccurred('Error Getting Tenant Connections', 'Unable to read response'));
    }
};

export const getTenantGuests = (tenantId) => async dispatch => {
    const result = await fetch(`/api/guests?tenantId=${tenantId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Tenant Guests', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
        dispatch({ type: 'GET_TENANT_GUESTS_SUCCESS', payload: { guests: items } });
    }
    catch(e) {
        dispatch(errorOccurred('Error Getting Tenant Guests', 'Unable to read response'));
    }
};

export const getTenantDetail = (tenantId) => async dispatch => {
    const result = await fetch(`/api/tenants/${tenantId}/detail`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Tenant Guests', 'Unexpected status code: ' + result.status));
        return;
    }
    try {
        const item = await result.json();
        dispatch({ type: 'GET_TENANT_DETAIL_SUCCESS', payload: { tenantDetail: item } });
    }
    catch(e) {
        dispatch(errorOccurred('Error Getting Tenant Detail', 'Unable to read response'));
    }
};