import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';
import Button from 'shared/components/Button';
import { roleNamesById } from 'constants.js';
import { colors } from 'styles/colors';

function UserInfoDialog(props) {
  const { open, onClose, user } = props;
  const connections = useSelector(x => x.tenantDetail.connections);
  const tenantDetail = useSelector(x => x.tenantDetail.detail);

  const handleAddClicked = async () => {
    onClose();
  };

  const connectionNameById = (connectionId) => {
    const connection = connections.find(x => x.id === connectionId);
    if (!connection) {
      console.error('connection not found:' + connectionId);
      return 'ERROR';
    }
    return connection.name;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px', width: '500px' }}>
        <div style={{ padding: '10px', fontSize: 'large', marginRight: '200px', marginBottom: '10px' }}>
          User Info for <label style={{fontStyle:'italic'}}>{user.name}</label>
        </div>

        <div className="table" style={{ fontSize: 'smaller', boxShadow: 'none', padding:'5px 20px' }}>
          <div className="table-row">
            <div className="col-3" style={{ color: colors.green, textAlign:'right' }}>Name</div>
            <div className="col-9">{user.name}</div>
          </div>
          <div className="table-row">
            <div className="col-3" style={{ color: colors.green, textAlign:'right' }}>E-mail</div>
            <div className="col-9">{user.email}</div>
          </div>
          <div className="table-row">
            <div className="col-3" style={{ color: colors.green, textAlign:'right' }}>Tenant</div>
            <div className="col-9">{tenantDetail.info.name}</div>
          </div>
          <div className="table-row">
            <div className="col-3" style={{ color: colors.green, textAlign:'right' }}>Role</div>
            <div className="col-9">{roleNamesById[user.role]}</div>
          </div>
          <div className="table-row">
            <div className="col-3" style={{ color: colors.green, textAlign:'right' }}>Title</div>
            <div className="col-9">{user.title}</div>
          </div>
          <div className="table-row">
            <div className="col-3" style={{ color: colors.green, textAlign:'right' }}>Phone</div>
            <div className="col-9">{user.phone}</div>
          </div>
          <div className="table-row">
            <div className="col-3" style={{ color: colors.green, textAlign:'right' }}>Connection</div>
            <div className="col-9">{connectionNameById(user.connectionId)}</div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium', marginTop: '10px' }}>
          <Button primary style={{ padding: '5px', margin: '5px' }} onClick={handleAddClicked}>Close</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default UserInfoDialog;