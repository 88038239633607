import reducers from './rootReducer';
import thunk from './lib/redux-thunk.js';
import reporter from './lib/redux-reporter.js';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();
export default createStore(
  reducers,
  applyMiddleware(thunk, reporter, sagaMiddleware)
);