import _ from 'lodash';

const initialState = {
  users: null,
};

export const usersList = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USERS_SUCCESS':
      const newState = _.cloneDeep(state);
      newState.users = _.cloneDeep(action.payload.users);
      return newState;
    default:
      return state;
  }
};