import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import SearchBox from 'shared/components/SearchBox';
import { fontSizes } from 'styles/vars'
import { sortAlphabeticallyByNumeric as sortNumeric } from 'lib/util';
import IconLinkButton from 'shared/components/IconLinkButton';
import { formatUnixTimeSeconds } from 'lib/util';
import Spinner from 'shared/components/spinner';
import { filterSearch } from 'lib/filterUtils';

function UserEventLog() {
  const [searchFilter, setSearchFilter] = useState('');
  const [events, setEvents] = useState(null);

  useEffect(() => {
    fetchLog();
  }, []);


  const fetchLog = async () => {
    const result = await fetch(`/api/usereventlog?limit=200`, {
        method: 'GET',
    });
    if(result.status !== 200) {
        console.log('error fetching usereventlog:', result.status);
        return;
    }
    let items = [];
    try {
        items = await result.json();
        setEvents(items);
    }
    catch(e) {
        console.error(e);
        return;
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchFilter(value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const eventInFilter = (evt) => {
    return filterSearch(evt, searchFilter, ['userId', 'email', 'tenantName', 'appEnvName', 'eventType', 'info', 'detail']);
  };

  if(!events)
    return <Spinner />;

  const filteredEvents = !!events && events.filter(x => eventInFilter(x));
  const sortedFiltered = !!filteredEvents && sortNumeric(filteredEvents, 'unixSeconds').reverse();
  const noFilteredEvents = events && events.length > 0 && sortedFiltered.length === 0;

  return (
    <>
      <div style={{padding: '15px', display:'flex', alignItems:'center'}}>
        <div style={{fontSize:fontSizes.l, marginRight:'30px'}}>
          User Event Log
        </div>

        <div style={{display:'flex'}}>
          <div style={{padding:'3px'}}>
            <SearchBox onChange={debouncedHandleSearchChange} />
          </div>
        </div>

        <div style={{display:'flex', alignItems:'center', padding:'3px', marginLeft:'15px', fontSize:fontSizes.s}}>
          <Link to='/app/usereventlogdownloads'>
            <IconLinkButton icon="download" onClick={() => {}} text="Download" />
          </Link>
        </div>

      </div>
      <div style={{padding:'5px', paddingLeft:'20px', fontSize:fontSizes.s, fontStyle:'italic', marginRight:'30px'}}>
        Recent events
      </div>
      <div style={{padding: '15px 0px 15px 15px', fontSize: fontSizes.r}}>
        {
            <div className="table zebra dense" style={{ fontSize: 'smaller' }}>
              <div className="table-row" style={{ fontWeight: 'bold' }}>
                <div className="col-2">E-Mail</div>
                <div className="col-2">App Env</div>
                <div className="col-2">Event</div>
                <div className="col-2">Info</div>
                <div className="col-2">Time</div>
                <div className="col-2">Tenant</div>
              </div>
              {
                noFilteredEvents && <div style={{padding:'10px'}}>No events found</div>
              }
              {
                (sortedFiltered &&  sortedFiltered.length) > 0 && sortedFiltered.map(evt => {
                  return (
                    <div className="table-row" key={evt.id}>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.email}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.appEnvName}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.eventType}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.info}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{formatUnixTimeSeconds(evt.unixSeconds)}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.tenantName}</div>
                    </div>
                  )
                })
              }
            </div>
        }
      </div>
    </>
  )
}

export default UserEventLog;