import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import SearchBox from 'shared/components/SearchBox';
import { fontSizes } from 'styles/vars'
import { uuid, formatUnixTimeSeconds } from 'lib/util';
import Spinner from 'shared/components/spinner';
import { filterSearch } from 'lib/filterUtils';

function TraceLog() {
  const [searchFilter, setSearchFilter] = useState('');
  const [events, setEvents] = useState(null);

  useEffect(() => {
    fetchLog();
  }, []);


  const fetchLog = async () => {
    const result = await fetch(`/api/tracelog?limit=1000`, {
        method: 'GET',
    });
    if(result.status !== 200) {
        console.log('error fetching usereventlog:', result.status);
        return;
    }
    let items = [];
    try {
        items = await result.json();
        setEvents(items);
    }
    catch(e) {
        console.error(e);
        return;
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if(value.length > 0 && value.length <= 2)
      return;
    setSearchFilter(value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const eventInFilter = (evt) => {
    return filterSearch(evt, searchFilter, ['level', 'message', 'source']);
  };

  if(!events) {
    return <Spinner />
  }

  const parsedEvents = [];
  for(let e of events) {
    try{
      parsedEvents.push(JSON.parse(e));
    }catch{}
  }
  const filteredEvents = parsedEvents && parsedEvents.filter(x => eventInFilter(x));
  const itemsToDisplay = filteredEvents;

  return (
    <>
      <div style={{padding: '15px', display:'flex', alignItems:'center'}}>
        <div style={{fontSize:fontSizes.l, marginRight:'30px'}}>
          Trace Log
        </div>

        <div style={{display:'flex'}}>
          <div style={{padding:'3px'}}>
            <SearchBox onChange={debouncedHandleSearchChange} />
          </div>
        </div>

      </div>
      <div style={{fontSize: fontSizes.r}}>
        {
            <div className="table zebra" style={{ fontSize: 'smaller' }}>
              <div className="table-row" style={{ fontWeight: 'bold' }}>
                <div className="col-1">Time</div>
                <div className="col-1">Level</div>
                <div className="col-9">Message</div>
              </div>
              {
                (!itemsToDisplay || itemsToDisplay.length === 0) && <div className="table-row col-12" style={{height:'50px', display:'flex', alignItems:'center'}}>No events</div>
              }
              {
                (itemsToDisplay &&  itemsToDisplay.length) > 0 && itemsToDisplay.map(l => {
                  let clr = l.level === 'ERROR' ? 'red' : l.level === "WARN" ? 'orange' : l.level === "INFO" ? 'green' : 'gray';
                  const fmt = `[${l.source}] ${l.message}`;
                  return (
                    <div className="table-row" style={{fontFamily:'monospace', color:clr}} key={uuid()}>
                      <div className="col-1" style={{fontFamily: 'monospace', height: '100%', display:'flex', alignItems:'center'}}>{formatUnixTimeSeconds(l.unixTimeSeconds)}</div>
                      <div className="col-1" style={{fontFamily: 'monospace', height: '100%', display:'flex', alignItems:'center'}}>{l.level}</div>
                      <div className="col-9" style={{fontFamily:'monospace', height: '100%', display:'flex', alignItems:'center'}}>{fmt}</div>
                    </div>
                  )
                })
              }
            </div>
        }
      </div>
    </>
  )
}

export default TraceLog;