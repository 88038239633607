import React from 'react';
import '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../styles/colors';

function IconLinkButton(props) {
  const { text, icon, onClick} = props;
  return (
      <div style={{display:'flex', padding:'3px', cursor:'pointer', color:colors.green}} onClick={onClick}>
        <div style={{display:'flex', alignItems:'center'}}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div style={{marginLeft:'3px'}}>{text}</div>
      </div>
  );
}

export default IconLinkButton;