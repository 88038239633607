import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, MenuItem } from '@material-ui/core';
import Button from 'shared/components/Button';
import ErrorDialog from 'shared/components/ErrorDialog';
import { postCommand } from 'shared/commandActions';
import { getTenantDetail } from 'components/tenants/tenantDetail/tenantDetailActions';
import CustomInput from 'shared/components/CustomInput';
import { fontSizes } from 'styles/vars';
import {
  guestRoles,
  roleNamesById,
} from 'constants.js';
import * as constants from 'constants.js';

function AddGuestDialog(props) {
  const dispatch = useDispatch();
  const { open, onClose, tenant, guests } = props;
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(constants.roles.User);
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });
  const [saving, setSaving] = useState(false); 
  const users = useSelector(x => x.usersList.users);

  const clearState = () => {
    setEmail('');
    setRole('User');
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddClicked = async (user) => {
    setSaving(true);
    const cmd = {
      type: 'CREATE_GUEST',
      payload: {
        guestInfo: {
          guestTenantId: tenant.id,
          homeTenantId: user.tenantId,
          role: role,
          userId: user.id,
        },
      },
    };

    const result = await postCommand(cmd);
    setSaving(false);
    if(result.error) {
      setErrorDialogState({visible: true, title:'Error Adding Guest', error: result.error, errorDescription: result.errorDescription });
    } else {
      dispatch(getTenantDetail(tenant.id));
      clearState();
      onClose();
    }
  };

  const handleCancelClicked = () => {
    clearState();
    onClose();
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  let errorMessage = '';
  let foundUser = users && users.find(x => x.email.toUpperCase() === email.toUpperCase());
  if(!foundUser) {
    errorMessage = 'User not found';
  }
  let existingGuest = foundUser && guests.find(x => x.userId === foundUser.id);
  if(existingGuest) {
    errorMessage = 'Guest already exists'
  }
  const emailValid = errorMessage === '';
  const saveEnabled = !saving && errorMessage === '';

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px', width: '350px' }}>
        <div style={{ padding: '10px', fontSize: 'large', marginBottom: '10px' }}>
          Add Guest
        </div>

        <div style={{ display: 'grid', gridRowGap: '10px', fontSize: fontSizes.r }}>
          <div className="row" style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
            <CustomInput
              label="E-Mail"
              error={!emailValid}
              value={email}
              onChange={handleEmailChange}
              autoFocus={true}
            />
          </div>
          <CustomInput
            label="Role"
            select={true}
            onChange={handleRoleChange}
            value={role}
          >
            {guestRoles.map(r => <MenuItem key={r} value={r}>{roleNamesById[r]}</MenuItem>)}
          </CustomInput>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium', marginTop: '10px' }}>
          <Button primary disabled={!saveEnabled} style={{ padding: '5px', margin: '5px' }} onClick={() => handleAddClicked(foundUser)}>Add</Button>
          <Button style={{ padding: '5px', margin: '5px' }} onClick={handleCancelClicked}>Cancel</Button>
        </div>
      </div>
      { errorDialogState.visible && <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={() => setErrorDialogState({...errorDialogState, visible: false})} /> }
    </Dialog>
  );
}

export default AddGuestDialog;