import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from 'shared/components/Button';
import { postCommand } from 'shared/commandActions';
import { getTenants } from '../tenantsList/tenantsListActions';
import { fontSizes } from 'styles/vars'
import Checkbox from 'shared/components/Checkbox';

function ChangeAllowedConnectionsDialog(props) {
  const dispatch = useDispatch();
  const { open, onClose, tenant } = props;
  const connections = useSelector(rs => rs.connections.connections);
  const [connectionState, setConnectionState] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
  if(connectionState === null) {
    const newState = {};
    for(let c of connections) {
      newState[c.id] = tenant.allowedConnectionIds.indexOf(c.id) !== -1;
    }
    setConnectionState(newState);
  }
  }, [connections, connectionState, tenant.allowedConnectionIds]);

  const handleUpdateClicked = async () => {
    setSaving(true);
    const allowedConnectionIds = connections.filter(x => connectionState[x.id] === true).map(x => x.id);
    const cmd = {
      type: 'UPDATE_TENANT',
      payload: {
        id: tenant.id,
        allowedConnectionIds: allowedConnectionIds,
      },
    };
    const result = await postCommand(cmd);
    setSaving(false);
    if(result.error) {
      console.error(result);
    } else {
      dispatch(getTenants());
      setConnectionState(null);
      onClose();
    }
  };

  const handleCancelClicked = () => {
    onClose();
  };

  const handleConnectionCheck = (e, connection) => {
    const newState = _.cloneDeep(connectionState);
    newState[connection.id] = e.target.checked;
    setConnectionState(newState);
  };

  if(!connectionState) {
    return <></>;
  }

  const validAllowedConnections = Object.values(connectionState).indexOf(true) !== -1;
  const enableSave = validAllowedConnections && !saving;

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px' }}>
        <div style={{ padding: '10px', fontSize: fontSizes.l }}>Change Allowed Connections</div>
        <div style={{ fontSize: fontSizes.r, fontStyle:'italic', marginBottom: '10px', padding:'10px' }}>
          Connections are how you enable SSO users to log into this tenant.  Users within this tenant are only allowed to use these connections.
        </div>

        <div style={{ display: 'flex', flexDirection:'column', fontSize: fontSizes.r, padding: '10px' }}>
          <div style={{padding: '5px', maxHeight:'600px', overflowY:'auto', border:'0.5px solid lightgray'}}>
            {
              connections.map(c => (
                <div key={c.id} style={{display:'flex', flexDirection:'row', height:'40px'}}>
                  <Checkbox checked={connectionState[c.id]} onChange={(e) => handleConnectionCheck(e, c)} />
                  <div style={{height:'100%', display:'flex', alignItems:'center' }}>{c.name}</div>
                </div>
              ))
            }
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button primary disabled={!enableSave} onClick={handleUpdateClicked}>Update</Button>
          <Button  onClick={handleCancelClicked}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ChangeAllowedConnectionsDialog;