import { combineReducers } from 'redux';

import * as currentUserReducers from './components/currentUser/currentUserReducer';
import * as tenantsListReducers from './components/tenants/tenantsList/tenantsListReducer';
import * as tenantDetailReducers from './components/tenants/tenantDetail/tenantDetailReducer';
import * as connectionReducers from './components/connections/connectionReducers';
import * as usersListReducers from './components/usersList/usersListReducer';
import * as groupListReducers from './components/groupsList/groupsListReducers';
import * as sharedReducers from './shared/sharedReducer';

const allReducers = Object.assign(
  {},
  currentUserReducers,
  tenantsListReducers,
  tenantDetailReducers,
  connectionReducers,
  usersListReducers,
  groupListReducers,
  sharedReducers,
);
export default combineReducers(allReducers);
