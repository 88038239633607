import { homeRedirect } from 'shared/redirects';
import { errorOccurred } from 'shared/sharedActions';

export const getConnections = () => async dispatch => {
    const result = await fetch(`/api/connections`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Connections', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
    }
    catch(e) {
        dispatch(errorOccurred('Error Getting Connections', 'Unable to read response'));
        return;
    }

    const success = { type: 'GET_CONNECTIONS_SUCCESS', payload: { connections: items } };
    dispatch(success);
};
