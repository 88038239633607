import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, MenuItem } from '@material-ui/core';
import Button from 'shared/components/Button';
import ErrorDialog from 'shared/components/ErrorDialog';
import { postCommand } from 'shared/commandActions';
import { getTenantDetail } from 'components/tenants/tenantDetail/tenantDetailActions';
import CustomInput from 'shared/components/CustomInput';
import { fontSizes } from 'styles/vars';
import { validateName, validateOptionalName } from 'lib/validators';
import {
  availableRolesForAssignment,
  roleNamesById,
} from 'constants.js';

function EditUserDialog(props) {
  const dispatch = useDispatch();
  const { open, onClose, tenant, user, tenantDetail } = props;
  const [name, setName] = useState(null);
  const [role, setRole] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [saving, setSaving] = useState(false); 
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });
  const availableRoles = availableRolesForAssignment[tenantDetail.userRoleInTenant];

  if(name == null) {
    setName(user.name);
    setRole(user.role);
    setTitle(user.title);
    setPhone(user.phone);
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleAddClicked = async () => {
    setSaving(true);
    const cmd = {
      type: 'UPDATE_USER',
      payload: {
        userInfo: {
          id: user.id,
          name: name,
          email: user.email,
          role: role ? role : 'User',
          tenantId: tenant.id,
          connectionId: user.connectionId,
          title: title,
          phone: phone,
        },
      },
    };
    const result = await postCommand(cmd);
    setSaving(false);
    if(result.error) {
      setErrorDialogState({visible: true, title:'Error Editing User', error: result.error, errorDescription: result.errorDescription });
    } else {
      dispatch(getTenantDetail(tenant.id));
      onClose();
    }
  };

  const handleCancelClicked = () => {
    onClose();
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const nameValid = validateName(name);
  const titleValid = validateOptionalName(title);
  const phoneValid = validateOptionalName(phone);
  const allValid = nameValid;

  const isDirty = user.name !== name
    || user.role !== role
    || user.title !== title
    || user.phone !== phone;

  const saveEnabled = isDirty && allValid && !saving;

  if(!availableRoles) {
    return <div></div>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px', width:'500px' }}>
        <div style={{ padding: '10px', fontSize: 'large', marginRight: '200px', marginBottom: '10px' }}>
          Edit User
        </div>

        <div style={{ fontSize: fontSizes.r }}>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <CustomInput
              label="Full Name"
              error={!nameValid}
              value={name}
              onChange={handleNameChange}
            />
          </div>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center', width: '50%' }}>
            <CustomInput
              label="Role"
              select={true}
              onChange={handleRoleChange}
              value={role}
            >
              {availableRoles.map(r => <MenuItem key={r} value={r}>{roleNamesById[r]}</MenuItem>)}
            </CustomInput>
          </div>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <CustomInput
              label="Title"
              error={!titleValid}
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="row" style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <CustomInput
              label="Phone"
              error={!phoneValid}
              value={phone}
              onChange={handlePhoneChange}
            />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium', marginTop: '10px' }}>
          <Button primary disabled={!saveEnabled} style={{ padding: '5px', margin: '5px' }} onClick={handleAddClicked}>Save Changes</Button>
          <Button style={{ padding: '5px', margin: '5px' }} onClick={handleCancelClicked}>Cancel</Button>
        </div>
      </div>
      { errorDialogState.visible && <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={() => setErrorDialogState({...errorDialogState, visible: false})} /> }
    </Dialog>
  );
}

export default EditUserDialog;