import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTenants } from 'components/tenants/tenantsList/tenantsListActions';
import { getUsers } from 'components/usersList/usersListActions.js';
import { getGroups } from './groupsListActions';
import { getMembersForGroup } from './groupsListActions';
import * as routeBuilder from '../../lib/routeBuilder.js';
import { fontSizes } from 'styles/vars'
import { colors } from 'styles/colors'
import { sortAlphabeticallyByField } from 'lib/util';
import { groupMembershipTypes } from '../../constants.js';
import Spinner from 'shared/components/spinner';

function GroupsList() {
  const dispatch = useDispatch();
  const users = useSelector(rs => rs.usersList.users);
  const tenants = useSelector(rs => rs.tenantsList.tenants);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const groups = useSelector(rs => rs.groupsList.groups);
  const members = useSelector(rs => rs.groupsList.membersInGroup);
  const userMembershipsInGroup = members && members.filter(x => x.type === groupMembershipTypes.User);
  const tenantMembershipsInGroup = members && members.filter(x => x.type === groupMembershipTypes.Tenant);
  const usersInGroup = (users && userMembershipsInGroup) && users.filter(u => userMembershipsInGroup.find(m => m.memberId === u.id));

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getTenants());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    setLoading(false);
  }, [members]);

  if (users === null || tenants === null) {
    return <Spinner />
  }

  const handleGroupClicked = (group) => {
    setLoading(true);
    dispatch(getMembersForGroup(group.id));
    setSelectedGroup(group);
  };

  const tenantById = (tenantId) => {
    return tenants.find(x => x.id === tenantId);
  }

  const tenantNameById = (tenantId) => {
    const tenant = tenantById(tenantId);
    if (!tenant) {
      console.error('tenant not found:' + tenantId);
      return 'ERROR';
    }
    return tenant.name;
  }

  const someUsersInGroup = userMembershipsInGroup && userMembershipsInGroup.length > 0;
  const someTenantsInGroup = tenantMembershipsInGroup && tenantMembershipsInGroup.length > 0;

  const groupFontStyle = (group) => {
    if (selectedGroup && group.id === selectedGroup.id) { return 'italic' }
    return '';
  };

  const sortedGroups = groups && sortAlphabeticallyByField(groups, 'name');
  const tenantsInGroup = tenantMembershipsInGroup && tenantMembershipsInGroup.map(x => tenantById(x.memberId)).filter(t => !!t);
  const sortedTenantsInGroup = tenantsInGroup && sortAlphabeticallyByField(tenantsInGroup, 'name');

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="col-4" style={{ fontSize: fontSizes.m, padding: '15px' }}>Groups</div>
          {
            selectedGroup && <div className="col-8" style={{ fontSize: fontSizes.m, padding: '15px' }}>Group: {selectedGroup.name}</div>
          }
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="table col-4" style={{ fontSize: fontSizes.m, padding:'0' }}>
            {
              sortedGroups && sortedGroups.map(g => (
                <div key={g.id}
                  className="table-row col-12"
                  style={{
                    cursor: 'pointer', color: colors.green,
                    fontStyle: groupFontStyle(g), fontSize: fontSizes.r
                  }}
                  onClick={() => handleGroupClicked(g)}>
                  {g.name}
                </div>
              ))
            }
          </div>
          <div className="col-8" style={{ fontSize: fontSizes.m, padding: '15px' }}>
            {
              selectedGroup && (
                <div>
                  <div style={{ padding: '10px 10px 10px 10px' }}>Users</div>
                  <div className="table">
                    <div className="table-row" style={{ fontSize: fontSizes.r }}>
                      <div className="col-3">Name</div>
                      <div className="col-3">Email</div>
                      <div className="col-3">Tenant</div>
                    </div>
                    {
                      (!loading && !someUsersInGroup) && <div className="table-row" style={{ fontSize: fontSizes.r }}><div className="col-12">No users</div></div>
                    }
                    {
                      (!loading && usersInGroup) && sortAlphabeticallyByField(usersInGroup, 'name').map(u => (
                        <div className="table-row" style={{ fontSize: fontSizes.r }} key={u.id}>
                          <div className="col-3">
                            {u.name}
                          </div>
                          <div className="col-3">
                            {u.email}
                          </div>
                          <div className="col-3">
                            <Link to={routeBuilder.buildTenantDetail(u.tenantId)}>{tenantNameById(u.tenantId)}</Link>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
            }
            {
              selectedGroup && (
                <div>
                  <div style={{ padding: '40px 40px 10px 10px' }}>Tenants</div>
                  <div className="table">
                    <div className="table-row" style={{ fontSize: fontSizes.r }}>
                      <div className="col-12">Tenant</div>
                    </div>
                    {
                      (!loading && !someTenantsInGroup) && <div className="table-row" style={{ fontSize: fontSizes.r }}><div className="col-12">No tenants</div></div>
                    }
                    {
                      (!loading && sortedTenantsInGroup) && sortedTenantsInGroup.map(t => (
                        <div className="table-row" style={{ fontSize: fontSizes.r }} key={t.id}>
                          <div className="col-12">
                            <Link to={routeBuilder.buildTenantDetail(t.id)}>{tenantNameById(t.id)}</Link>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupsList;