import React from 'react';
import { useSelector } from 'react-redux';
import { fontSizes } from 'styles/vars'
import Spinner from 'shared/components/spinner';

function UserLanding() {
  const profile = useSelector(x => x.currentUser.profile);
  if(!profile)
    return <Spinner />

  return (
    <>
      <div style={{padding: '15px', display:'flex', flexDirection:'column'}}>
        <div style={{fontSize:fontSizes.m, marginRight:'30px', padding:'10px'}}>
          <div>You are logged in as {profile.name} ({profile.email}).</div>
          <div style={{marginTop:'20px'}}>Please contact GovInvest support or your local administrator for access.</div>
        </div>
      </div>
    </>
  )
}

export default UserLanding;