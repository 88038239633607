import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from 'shared/components/Button';
import ErrorDialog from 'shared/components/ErrorDialog';
import { postCommand } from 'shared/commandActions';
import CustomInput from 'shared/components/CustomInput';
import { fontSizes } from 'styles/vars'
import Auth from 'shared/auth';
import { colors } from 'styles/colors';

function ChangePasswordDialog(props) {
  const dispatch = useDispatch();
  const userProfile = useSelector(rs => rs.currentUser.profile);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const { open, onClose, disableCancel } = props;
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });
  const [saving, setSaving] = useState(false); 

  const handleChangeClicked = async () => {
    setSaving(true);
    const cmd = {
      type: 'CHANGE_PASSWORD',
      payload: {
        userId: userProfile.userId,
        currentPassword: currentPassword,
        newPassword: newPassword,
      }
    };
    const result = await postCommand(cmd);
    setSaving(false);
    if (result.error) {
      setErrorDialogState({ visible: true, title: 'Error Changing Password', error: result.error, errorDescription: result.errorDescription });
    } else {
      var auth = new Auth();
      await auth.refreshToken();
      dispatch({ type: 'PROFILE_AVAILABLE', payload: auth.getProfile()})
      onClose();
    }
  };

  const handleCancelClicked = () => {
    onClose();
  };

  const handleCurrentChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const isLongEnough = (pw) => {
    return pw.length >= 8;
  };

  const currentPasswordValid = currentPassword !== '';
  const newPasswordValid = isLongEnough(newPassword);
  const confirmNewPasswordValid = newPassword === confirmNewPassword;
  const enableSave = currentPasswordValid && newPasswordValid && confirmNewPasswordValid && !saving;

  const handleOnClose = (e, r) => {
    if(r === 'backdropClick') {
      return;
    }
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <div style={{ padding: '15px', width: '400px' }}>
        <div style={{ padding: '10px', fontSize: fontSizes.l }}>Change Password</div>
        <div className="row" style={{ padding: '10px', fontSize: fontSizes.s, fontStyle: 'italic', color: colors.darkGrey }}>Passwords must be at least 8 characters long.  We strongly recommend the use of a long random password (20+) and use of a password manager.</div>

        <div className="row" style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
          <CustomInput
            type="password"
            label="Current Password"
            error={!currentPasswordValid}
            onChange={handleCurrentChange}
          />
        </div>

        <div className="row" style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
          <CustomInput
            type="password"
            label="New Password"
            error={!newPasswordValid}
            onChange={handleNewChange}
          />
        </div>

        <div className="row" style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
          <CustomInput
            type="password"
            label="Confirm New Password"
            error={!confirmNewPasswordValid}
            onChange={handleConfirmNewChange}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button primary disabled={!enableSave} onClick={handleChangeClicked}>Change</Button>
          { !disableCancel && <Button onClick={handleCancelClicked}>Cancel</Button> }
        </div>
      </div>
      { errorDialogState.visible && <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={() => setErrorDialogState({...errorDialogState, visible: false})} /> }
    </Dialog>
  );
}

export default ChangePasswordDialog;