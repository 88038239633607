import _ from 'lodash';

const initialState = {
  users: null,
  guests: null,
  connections: null,
  detail: null
};

export const tenantDetail = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TENANT_DETAIL_SUCCESS': {
        const newState = _.cloneDeep(state);
        newState.detail = _.cloneDeep(action.payload.tenantDetail);
        return newState;
      }
    case 'GET_TENANT_GUESTS_SUCCESS': {
        const newState = _.cloneDeep(state);
        newState.guests = _.cloneDeep(action.payload.guests);
        return newState;
      }
    case 'GET_TENANT_USERS_SUCCESS': {
        const newState = _.cloneDeep(state);
        newState.users = _.cloneDeep(action.payload.users);
        return newState;
      }
    case 'GET_TENANT_CONNECTIONS_SUCCESS': {
        const newState = _.cloneDeep(state);
        newState.connections = _.cloneDeep(action.payload.connections);
        return newState;
      }
    default:
      return state;
  }
};