import React from 'react';
import Header from '../header/Header';
import MainContent from '../mainContent/MainContent';
import Footer from '../footer/Footer';

function MainPage() {
  return (
    <div id="main-page-container">
      <div id="content-wrap">
        <div id="header-row" className="row">
          <Header />
        </div>
        <div className="row">
          <MainContent />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MainPage;