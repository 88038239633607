import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getTenants } from '../tenantsList/tenantsListActions';
import { getTenantConnections, getTenantDetail } from './tenantDetailActions';
import { fontSizes } from 'styles/vars';
import RenameTenantDialog from './RenameTenantDialog';
import ConfirmDialog from 'shared/components/ConfirmDialog';
import AlertDialog from 'shared/components/AlertDialog';
import IconLinkButton from 'shared/components/IconLinkButton.js';
import { tenantAdminRoles } from 'constants.js';
import ChangeAllowedConnectionsDialog from './ChangeAllowedConnectionsDialog';
import UpdateImageDialog from './UpdateImageDialog';
import TenantImage from 'shared/components/tenantImage/TenantImage';
import UsersTable from './usersTable/UsersTable';
import GuestsTable from './guestsTable/GuestsTable';
import GroupMembershipDialog from './groupMembershipDialog/GroupMembershipDialog';
import EnabledAppEnvDialog from './enabledAppEnvDialog/EnabledAppEnvDialog';
import Spinner from 'shared/components/spinner';
import { getUsers } from 'components/usersList/usersListActions';

function TenantDetail() {
  const dispatch = useDispatch();
  const [showRename, setShowRename] = useState(false);
  const [showChangeConnections, setShowChangeConnections] = useState(false);
  const [showUpdateImageDialog, setShowUpdateImageDialog] = useState(false);
  const [showUserGroups, setShowUserGroups] = useState(false);
  const [showEnabledAppEnvDialog, setShowEnabledAppEnvDialog] = useState(false);
  const [confirmState, setConfirmState] = useState({ visible: false, title: '', message: '' });
  const [alertState, setAlertState] = useState({ visible: false, title: '', message: '' });
  const tenants = useSelector(x => x.tenantsList.tenants);
  const connections = useSelector(x => x.tenantDetail.connections);
  const tenantDetail = useSelector(x => x.tenantDetail.detail);
  const prms = useParams();
  const tenantId = prms.tenantId;
  const loadedTenantId = !!tenantDetail ? tenantDetail.info.id : null;

  useEffect(() => {
    if(loadedTenantId !== tenantId)
      dispatch(getTenants());
    dispatch(getTenantConnections(tenantId));
    dispatch(getTenantDetail(tenantId));
    dispatch(getUsers());
  }, [dispatch, tenantId, loadedTenantId]);

  if (!tenantDetail || !tenants || !connections) {
    return <Spinner />;
  }
  if(loadedTenantId !== tenantId) {
    return <Spinner />;
  }
  const tenant = tenants.find(x => x.id === tenantId);

  const handleCloseChangeConnections = () => {
    setShowChangeConnections(false);
  };

  let showTenantAdminControls = !tenantDetail.readonly && tenantDetail.showAdminControls;
  const showGuestsTable = tenantDetail && tenantDetail.showGuests;
  const showTenantType = tenantAdminRoles.indexOf(tenantDetail.userRoleInTenant) !== -1;

  return (
    <div key={tenantId}>
      <div className="row">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: fontSizes.l, margin: '15px', width:'40px', height:'40px' }}>
            <TenantImage tenantId={tenantId} width='40px' />
          </div>
          <div style={{ fontSize: fontSizes.l, padding: '15px 25px 15px 5px', display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
            {tenant.name}
          </div>
          {
            showTenantAdminControls &&
            <div style={{ display: 'grid', gridAutoFlow: 'column' }}>
              <HeaderButtonWrapper onClick={() => setShowRename(true)}>
                <IconLinkButton icon="edit" text="Rename" />
              </HeaderButtonWrapper>
              <HeaderButtonWrapper onClick={() => setShowEnabledAppEnvDialog(true)}>
                <IconLinkButton icon="sitemap" text="Environments" />
              </HeaderButtonWrapper>
              <HeaderButtonWrapper onClick={() => setShowUserGroups(true)}>
                <IconLinkButton icon="users" text="Groups" />
              </HeaderButtonWrapper>
              <HeaderButtonWrapper onClick={() => setShowChangeConnections(true)}>
                <IconLinkButton icon="plug" text="Connections" />
              </HeaderButtonWrapper>
              <HeaderButtonWrapper onClick={() => setShowUpdateImageDialog(true)}>
                <IconLinkButton icon="image" text="Image" />
              </HeaderButtonWrapper>
            </div>
          }
        </div>
      </div>
      {
        showTenantType && (
          <div style={{ fontSize: fontSizes.r, paddingLeft:'15px', paddingBottom:'15px', display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
            Tenant Type: {tenant.type}
          </div>
        )
      }

      <UsersTable />
      {
        showGuestsTable && (
          <>
            <div style={{height:'50px'}} />
            <GuestsTable />
          </>
        )
      }

      {showRename && <RenameTenantDialog currentName={tenant.name} tenantId={tenantId} open={showRename} onClose={() => setShowRename(false)} />}
      {confirmState.visible && <ConfirmDialog confirmState={confirmState} open={confirmState.visible} onConfirm={confirmState.onConfirm} onClose={() => setConfirmState({ ...confirmState, visible: false })} />}
      {alertState.visible && <AlertDialog alertState={alertState} open={alertState.visible} onClose={() => setAlertState({ ...alertState, visible: false })} />}
      {showChangeConnections && <ChangeAllowedConnectionsDialog tenant={tenant} open={showChangeConnections} onClose={handleCloseChangeConnections} />}
      {showUpdateImageDialog && <UpdateImageDialog tenantId={tenant.id} open={showUpdateImageDialog} onClose={() => setShowUpdateImageDialog(false)} />}
      {showUserGroups && <GroupMembershipDialog tenant={tenant} open={showUserGroups} onClose={() => setShowUserGroups(false)} />}
      {showEnabledAppEnvDialog && <EnabledAppEnvDialog tenant={tenant} open={showEnabledAppEnvDialog} onClose={() => setShowEnabledAppEnvDialog(false)} />}
    </div>
  );
}

const HeaderButtonWrapper = styled.div`
  padding: 5px 5px;
  &:hover {
    background: #EEEEEE;
    cursor: pointer;
  }
`;

export default TenantDetail;