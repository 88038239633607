import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import styled from 'styled-components';

const CustomInput = props => {
  return (
    <TextFieldThemed
      value={props.value}
      label={props.label || ''}
      error={props.error}
      size={props.size || 'small'}
      type={props.type || 'text'}
      select={props.select ?? false}
      variant='outlined'
      placeholder={props.placeholder}
      disabled={props.disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position={props.iconPosition || 'start'}>
            <CredentialIconWrapper>
              {props.Icon}
            </CredentialIconWrapper>
          </InputAdornment>
        ),
      }}
      onChange={props.onChange}
      onKeyUp={props.onKeyUp}
      autoFocus={props.autoFocus}
    >
      {props.children && props.children}
    </TextFieldThemed>
  );
}

export default CustomInput;

const TextFieldThemed = styled(TextField)`
  width: 100%;
  & input {
    font-size: 14px;
  }
  & .MuiSelect-root {
    font-size: 14px;
  }
  & label.Mui-focused {
    color: ${props =>
      props.error ? 'var(--color-theme-error)' : 'var(--color-theme-primary)'};
  }
  & .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline {
    border-color: ${props =>
      props.error ? 'var(--color-theme-error)' : 'var(--color-theme-primary)'};
  }
  & .MuiInput-underline {
    &:after {
      border-bottom-color: ${props =>
        props.error
          ? 'var(--color-theme-error)'
          : 'var(--color-theme-primary)'};
    }
`;

const CredentialIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  & svg {
    font-size: 18px;
  }
`;
