import qs from 'qs';
import urlJoin from 'url-join';
import { uuid } from './uuidutils';
import Auth from './auth';

export default class AuthManager {
  constructor() {
    this.envConfig = window.env;
    this.config = {
      clientId: this.envConfig.clientId,
    };
  }

  async handleStart() {
    console.log('AuthManager.handleStart for path: ' + window.location.pathname);

    if(window.location.pathname === "/auth/error") {
      const queryStrings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      this.clearLocalStorage();
      return { mode: 'error', error: queryStrings.error, errorDescription: queryStrings.error_description };
    }

    if(window.location.pathname === "/auth/app_login") {
      const auth = new Auth();
      const success = await auth.tryRefreshProfile();
      if(success) {
          this.redirectAlreadyLoggedIn();
          return { mode: 'already_logged_in' };
        }
      const queryStrings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      const decoded = atob(queryStrings.state);
      sessionStorage.setItem("ium_app_login_state", decoded)
      return { mode: 'app_login' };
    }

    if(window.location.pathname === '/auth/device_callback') {
      return { mode: 'device_callback' };
    }

    if(window.location.pathname === "/auth/device_login") {
      const queryStringsProvided = window.location.search !== undefined;
      if(!queryStringsProvided) {
          return { mode:'error', error: 'Bad callback', errorDescription: 'Missing required query strings' };
      }
      const queryStrings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      sessionStorage.setItem("ium_device_code", queryStrings.device_code);
      sessionStorage.setItem("ium_device_scope", queryStrings.scope);
      sessionStorage.setItem("ium_device_title", queryStrings.title);
      return { mode: 'device_login' };
    }

    return { mode: 'none' };
  }

  redirectAlreadyLoggedIn() {
    const loginState = sessionStorage.getItem('ium_app_login_state');
    const loginStateObj = JSON.parse(loginState);
    var url = loginStateObj.redirectUri +
      '?logged_in=true' +
      '?state=' + btoa(loginState);
    window.location.replace(url);
  }

  doSsoLogin(email) {
    console.log('Doing SSO Login');
    const loginState = JSON.parse(sessionStorage.getItem('ium_app_login_state'));
    const redirect_uri = loginState.redirectUri;
    const scope = loginState.scope.join(' ');

    var url = urlJoin(this.envConfig.baseUrl, '/oauth2/authorize') +
      '?response_type=code' +
      '&scope=' + encodeURI(scope) +
      '&client_id=' + loginState.clientId +
      '&state=' + loginState.state +
      '&nonce=' + loginState.nonce +
      '&login_hint=' + encodeURI(email) +
      '&redirect_uri=' + encodeURI(redirect_uri);

    window.location.href = url;
  }

  async doUserDatabaseLogin(email, password) {
    console.log('Doing User Database Login');
    const loginState = JSON.parse(sessionStorage.getItem('ium_app_login_state'));
    var redirect_uri = loginState.redirectUri;
    var scope = loginState.scope.join(' ');

    var url = urlJoin(this.envConfig.baseUrl, '/oauth2/authorize') +
      '?response_type=code' +
      '&scope=' + encodeURI(scope) +
      '&client_id=' + loginState.clientId +
      '&state=' + loginState.state +
      '&nonce=' + loginState.nonce +
      '&redirect_uri=' + encodeURI(redirect_uri) +
      '&email=' + encodeURI(email);

    const bodyText = password;
    const response = await fetch(url, {
      method: 'POST',
      redirect: 'manual',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: bodyText,
    });
    if(response.status === 200) {
      const responseUri = await response.text();
      window.location.href = responseUri;
    }
    else {
      console.log('Unexpected response status from db login: ' + response.status);
      console.log(await response.text());
    }
  }

  async doDeviceDatabaseLogin(email, password) {
    console.log('Doing Device Database Login');
    const deviceCode = sessionStorage.getItem('ium_device_code');
    const scope = sessionStorage.getItem('ium_device_scope');
    const nonce = uuid();
    var redirect_uri = urlJoin(this.envConfig.baseUrl, 'auth/device_callback')

    var url = urlJoin(this.envConfig.baseUrl, '/oauth2/authorize') +
      '?response_type=device_code' +
      '&scope=' + encodeURI(scope) +
      '&client_id=' + this.config.clientId +
      '&nonce=' + encodeURI(nonce) +
      '&redirect_uri=' + encodeURI(redirect_uri) +
      '&device_code=' + deviceCode +
      '&email=' + encodeURI(email);

    const bodyText = password;
    const response = await fetch(url, {
      method: 'POST',
      redirect: 'manual',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: bodyText,
    });
    if(response.status === 200) {
      const responseUri = await response.text();
      window.location.href = responseUri;
    }
    else {
      console.log('Unexpected response status from db login: ' + response.status);
      console.log(await response.text());
    }
  }

  doDeviceSsoLogin(email) {
    console.log('Doing Device Sso Login');
    const deviceCode = sessionStorage.getItem('ium_device_code');
    const scope = sessionStorage.getItem('ium_device_scope');
    const nonce = uuid();
    var redirect_uri = urlJoin(this.envConfig.baseUrl, 'auth/device_callback')

    var url = urlJoin(this.envConfig.baseUrl, '/oauth2/authorize') +
      '?response_type=device_code' +
      '&scope=' + encodeURI(scope) +
      '&client_id=' + this.config.clientId +
      '&login_hint=' + encodeURI(email) +
      '&nonce=' + encodeURI(nonce) +
      '&redirect_uri=' + encodeURI(redirect_uri) +
      '&device_code=' + deviceCode;

    window.location.href = url;
  }

  clearLocalStorage() {
    localStorage.removeItem('ium_nonce');
  }

  getDeviceTitle() {
    const title = sessionStorage.getItem("ium_device_title");
    if(!title) {
      console.error('No device app title');
      return '[Device App Title]';
    }
    return title;
  }

  getAppTitle() {
    const stateText = sessionStorage.getItem("ium_app_login_state");
    if(!stateText) {
      console.error('No app title');
      return 'User Management';
    }
    const state = JSON.parse(stateText);
    return state.title;
  }
}