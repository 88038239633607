import _ from 'lodash';

const initialState = {
  connections: null,
};

export const connections = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CONNECTIONS_SUCCESS':
      const newState = _.cloneDeep(state);
      newState.connections = _.cloneDeep(action.payload.connections);
      return newState;
    default:
      return state;
  }
};