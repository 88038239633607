import qs from 'qs';
import urlJoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';
import authConfig from './authConfig';

export default class Auth {
  constructor() {
    this.initiateLogin = this.initiateLogin.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleTokenResponse = this.handleTokenResponse.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.logout = this.logout.bind(this);
    this.switchUser = this.switchUser.bind(this);
    this.clearLocalStorage = this.clearLocalStorage.bind(this);
    this.nowUnixSeconds = this.nowUnixSeconds.bind(this);
    this.tryRefreshProfile = this.tryRefreshProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.isRefreshTokenValid = this.isRefreshTokenValid.bind(this);
    this.isAccessTokenValid = this.isAccessTokenValid.bind(this);    
    this.logoutClearStorageAndGoHome = this.logoutClearStorageAndGoHome.bind(this);
    this._logoutOrSwitchUser = this._logoutOrSwitchUser.bind(this);
    this.setRefreshId = this.setRefreshId.bind(this);
    this.getRefreshId = this.getStorageRefreshId.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.getCookieRefreshId = this.getCookieRefreshId.bind(this);
    this.scheduleRenewalWithRandom = this.scheduleRenewalWithRandom.bind(this);
    this.checkAuth = this.checkAuth.bind(this);
    this.testCookieCredentials = this.testCookieCredentials.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.hasAuthorizedScope = this.hasAuthorizedScope.bind(this);
    this.getRenewScheduled = this.getRenewScheduled.bind(this);

    this.config = authConfig;
    const storagePrefix = this.config.storagePrefix;
    this.storageNames = {
      loginState: storagePrefix + '_login_state2',
      nonce: storagePrefix + '_nonce',
      requestedUrl: storagePrefix + '_requested_url2',
      profile: 'gi_profile2',
      refreshId: storagePrefix + '_refresh_id2'
    };
  }

  async handleStart() {
    console.log('Auth handling start for path: ' + window.location.pathname);

    if (window.location.pathname === "/logout_callback") {
      console.log('redirecting from logout callback')
      await this.logoutClearStorageAndGoHome();
      return;
    }

    if (window.location.pathname === "/callback") {
      const storedState = sessionStorage.getItem(this.storageNames.loginState);
      if (!storedState) {
        console.error('callback state not found')
        return { mode: 'error', error: 'callback state not found', errorDescription: 'You may have bookmarked the login page.  Please bookmark the following URL: ' + this.config.appBaseUrl };
      }

      const queryStringsProvided = window.location.search !== undefined;
      if (!queryStringsProvided) {
        return { mode: 'error', error: 'Bad callback', errorDescription: 'Missing required query strings' };
      }

      const queryStrings = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      if (queryStrings.logged_in) {
        await this.tryRefreshProfile();
        return { mode: 'authenticated' };
      }

      if (queryStrings.error) {
        return { mode: 'error', error: queryStrings.error, errorDescription: queryStrings.error_description };
      }
      if (!queryStrings.state) {
        return { mode: 'error', error: 'Bad state', errorDescription: 'Missing state' };
      }
      if (!queryStrings.code) {
        return { mode: 'error', error: 'Bad code', errorDescription: 'Missing code' };
      }

      if (storedState !== queryStrings.state) {
        return { mode: 'error', error: 'Invalid stae', errorDescription: 'Invalid state' };
      }

      const tokenResult = await this._getTokenWithCode(queryStrings.code);
      const tokenResponseBody = await tokenResult.text();
      this.handleTokenResponse(tokenResponseBody);
      return { mode: 'authenticated' };
    }

    const refreshSuccess = await this.tryRefreshProfile();
    if (!refreshSuccess) {
      console.log('Unable to refresh profile')
      if (this.isRefreshTokenValid()) {
        console.log('Refresh token not valid')
        return { mode: 'not_authenticated' };
      } else {
        console.log('Starting refresh')
        await this.refreshToken(false);
      }
    }

    if (this.isAccessTokenValid()) {
      return { mode: 'authenticated' };
    } else {
      return { mode: 'not_authenticated' };
    }
  }

  async tryRefreshProfile() {
    try {
      const response = await fetch(urlJoin(this.config.iumBaseUrl, '/api/profile'), {
        method: 'GET',
        credentials: 'include',
      });
      if (response.status !== 200) {
        console.error('Error refreshing profile.')
        return false;
      }
      try {
        var jsonContent = await response.json();
        console.log('Refresh profile successful');
        this.updateProfile(jsonContent);
        return true;
      } catch (e) {
        console.error('error parsing profile:', e);
        return false;
      }
    } catch(e) {
      console.error('Error refreshing profile', e);
      return false;
    }
  }

  updateProfile(profile) {
    console.log('updating profile');
    localStorage.setItem(this.storageNames.profile, JSON.stringify(profile));
  }

  initiateLogin() {
    console.log('Initiating Login');
    sessionStorage.setItem(this.storageNames.requestedUrl, window.location.pathname);
    const state = {
      uuid: uuidv4(),
    };
    const serializedState = JSON.stringify(state);
    const encodedState = btoa(serializedState);
    const nonce = uuidv4();

    sessionStorage.setItem(this.storageNames.loginState, encodedState);
    sessionStorage.setItem(this.storageNames.nonce, nonce);

    const redirectUri = urlJoin(this.config.appBaseUrl, '/callback');
    var scope = 'ium';
    if(!!this.config.appEnvId)
      scope = scope + ' appenv:' + this.config.appEnvId;

    const url = urlJoin(this.config.iumBaseUrl, '/oauth2/authorize') +
      '?response_type=code' +
      '&scope=' + encodeURI(scope) +
      '&client_id=' + this.config.clientId +
      '&state=' + encodeURI(encodedState) +
      '&nonce=' + encodeURI(nonce) +
      '&redirect_uri=' + encodeURI(redirectUri);

    window.location.href = url;
  }

  handleTokenResponse(tokenResponseText) {
    console.log('Token Recieved')
    const tokenResponse = JSON.parse(tokenResponseText);
    const refreshIdCookie = this.getCookieRefreshId();
    this.setRefreshId(refreshIdCookie);
    this.updateProfile(tokenResponse.profile);
  }

  async _getTokenWithCode(code) {
    const bodyObj = {
      grant_type: 'authorization_code',
      code: code,
      client_id: this.config.clientId,
    };
    const bodyText = qs.stringify(bodyObj);
    const response = await fetch(urlJoin(this.config.iumBaseUrl, '/oauth2/token'), {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'x-www-form-urlencoded'
      }),
      body: bodyText,
    });
    return response;
  }

  getProfile() {
    const profile = localStorage.getItem(this.storageNames.profile);
    if (!profile || profile === 'undefined' || profile === 'null') {
      return null;
    }
    try {
      return JSON.parse(profile);
    } catch (e) {
      console.error('Error parsing profile.', e);
      return null;
    }
  }

  logout() {
    this._logoutOrSwitchUser(false);
  }

  switchUser() {
    this._logoutOrSwitchUser(true);
  }

  _logoutOrSwitchUser(switchUser) {
    const p = {
      switchUser: switchUser,
      returnTo: urlJoin(this.config.appBaseUrl, 'logout_callback'),
      clientId: this.config.clientId,
    };
    const qsText = qs.stringify(p);
    var logoutUri = (urlJoin(this.config.iumBaseUrl, '/oauth2/logout') + '?' + qsText);
    window.location.href = logoutUri;
  }

  clearLocalStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  getRenewScheduled() {
    const scheduled = Auth.renewScheduled;
    return scheduled;
  }

  scheduleRenewal() {
    console.log('Scheduling Renewal')
    Auth.renewScheduled = true;
    setTimeout(() => this.refreshToken(true), this.config.refreshInterval);
  }

  scheduleRenewalWithRandom() {
    console.log('Scheduling Renewal Random')
    Auth.renewScheduled = true;
    const rndInt = Math.floor(Math.random() * 60000);
    setTimeout(() => this.refreshToken(true), this.config.refreshInterval + rndInt);
  }

  async logoutClearStorageAndGoHome() {
    await fetch(urlJoin(this.config.iumBaseUrl, '/api/logout'), { //this should clear cookies
      method: 'GET',
      credentials: 'include',
    });
    this.clearLocalStorage();
    window.location.replace(this.config.appBaseUrl);
  }

  isRefreshIdCurrent() {
    const storageRefreshId = this.getStorageRefreshId();
    const cookieRefreshId = this.getCookieRefreshId();
    return storageRefreshId === cookieRefreshId;
  }

  async refreshToken(reschedule) {
    console.log('Refreshing Token');

    //handle case where what's in storage is stale
    if (!this.isRefreshIdCurrent()) {
      console.log('- refresh id stale')
      //if storage stale, check if cookie is valid
      const refreshProfileSuccess = await this.tryRefreshProfile();

      //if cookie not valid, nothing to do.  clear storage and go home.
      if (!refreshProfileSuccess) {
        console.log('-- refresh failed; go home')
        await this.logoutClearStorageAndGoHome();
        return;
      }

      console.log('-- success')
      if (reschedule) {
        this.scheduleRenewalWithRandom();
      }
      return; //another session has updated cookie already.  do nothing.
    }

    //if you got this far, what's in storage should match the cookie

    //i don't expect this to be the case.  it's just a guard.
    const profile = this.getProfile();
    if (!profile || !profile.clientId) {
      console.error('- Cant refresh token because there is no profile.');
      await this.logoutClearStorageAndGoHome();
      return;
    }

    //Ok, cleared to refresh
    const bodyObj = {
      grant_type: 'refresh_token',
      client_id: profile.clientId, //we are using this because the user may have logged in using ium or prometheus
    };
    const bodyText = qs.stringify(bodyObj);
    const response = await fetch(urlJoin(this.config.iumBaseUrl, '/oauth2/token'), {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'x-www-form-urlencoded'
      }),
      body: bodyText,
    });
    if (Number.parseInt(response.status) >= 400) {
      console.log('- Failed refreshing token');
      await this.logoutClearStorageAndGoHome();
      return;
    }
    console.log('- refresh success')
    const tokenResponseBody = await response.text();
    this.handleTokenResponse(tokenResponseBody);
    if (reschedule) {
      this.scheduleRenewalWithRandom();
    }
  }

  //two ways to be admin: have a SysAdmin role, or have <appEnvId>:Admin scope
  isAdmin() {
    const profile = this.getProfile();
    if(!profile) {
      return false;
    }
    const sysAdmin = profile.scope.find(x => x.toLowerCase() === 'global:sysadmin');
    if(!!sysAdmin) {
      return true;
    }
    if(!!this.config.appEnvId) {
      const adminScope = this.config.appEnvId + ':admin';
      const adminViaScope = profile.scope.find(x => x.toLowerCase() === adminScope.toLowerCase());
      return !!adminViaScope;
    }
    return false;
  }

  nowUnixSeconds() {
    return Math.floor(Date.now() / 1000);
  }

  async testCookieCredentials() {
    console.log('testing cookie credentials');
    return await this.tryRefreshProfile();
  }

  hasAuthorizedScope() {
    const profile = this.getProfile();
    if(!profile) {
      return false;
    }
    if(!!this.config.appEnvId) {
      const appEnvScope = 'appenv:' + this.config.appEnvId;
      const hasAppEnvScope = profile.scope.find(x => x.toLowerCase() === appEnvScope.toLowerCase());
      return !!hasAppEnvScope;
    }
    return true;
  }

  isRefreshTokenValid() {
    const expiresAt = this.getCookie('X-Refresh-Expires-At2');
    if (!expiresAt) {
      return false;
    }
    const parsedExpiresAt = parseInt(expiresAt);
    const expired = this.nowUnixSeconds() > parsedExpiresAt;
    if (expired) {
      console.log('Refresh token expired')
      return false;
    }
    return true;
  }

  isAccessTokenValid() {
    const expiresAt = this.getCookie('X-Access-Expires-At2');
    if (!expiresAt) {
      return false;
    }
    const parsedExpiresAt = parseInt(expiresAt);
    const expired = this.nowUnixSeconds() > parsedExpiresAt;
    if (expired) {
      console.log('Access token expired')
      return false;
    }
    return true;
  }

  isLoggedIn() {
    if(!this.isAccessTokenValid()) {
      console.log('isLoggedIn(): access token not valid')
      return false;
    }
    if(!this.getProfile()) {
      console.log('isLoggedIn(): no profile')
      return false;
    }
    return true;
  }

  getRequestedUrl() {
    return sessionStorage.getItem(this.storageNames.requestedUrl);
  }

  clearRequestedUrl() {
    return sessionStorage.removeItem(this.storageNames.requestedUrl);
  }

  getCookieRefreshId() {
    return this.getCookie('X-Refresh-Id2');
  }

  getStorageRefreshId() {
    return localStorage.getItem(this.storageNames.refreshId);
  }

  setRefreshId(refreshId) {
    localStorage.setItem(this.storageNames.refreshId, refreshId);
  }

  changePassword(disableCancel) {
    const dc = !!disableCancel;
    window.location.replace(
      urlJoin(this.config.iumBaseUrl, '/app/change_password?disableCancel=' + dc + '&redirectTo=' + encodeURI(this.config.appBaseUrl))
    );
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  checkAuth() {
    var accessTokenValid = this.isAccessTokenValid();
    if (!accessTokenValid) {
      console.log('Check auth redirecting home because access token invalid');
      window.location.replace(this.config.appBaseUrl);
      return;
    }
  }
}
