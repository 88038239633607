import React from 'react';
import '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../styles/colors';
import styled from 'styled-components';

function IconButton(props) {
  const { icon, onClick, color, disabled } = props;
  let colorToUse = color ?? colors.green;
  if(disabled) {
    colorToUse = colors.grey;
  }
  const handleClick = () => {
    if(disabled) {
      return;
    }
    onClick();
  };
  
  return (
    <div style={{display:'flex', alignItems:'center', cursor:'pointer', 
                  color:colorToUse, padding:'3px'}}
         onClick={handleClick} disabled={disabled}>
      <IconStyled icon={icon} disabled={disabled} size={props.size} />
    </div>
  );
}

const IconStyled = styled(FontAwesomeIcon)`
  &:hover {
    transform: scale(1.1);
  }
`;

export default IconButton;