import qs from 'qs';
import ChangePasswordDialog from 'components/headerProfile/ChangePasswordDialog';
import { errorRedirect } from 'shared/redirects';

function ChangePasswordPage(props) {
  const url_domain = (data) => {
    const a = document.createElement('a');
    a.href = data;
    return a.hostname;
  };

  const queryStrings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (!queryStrings.redirectTo) {
    console.error('No redirectTo');
    errorRedirect('no_redirectto', 'Missing redirect endpoint')
    return <div></div>;
  }
  const domain = url_domain(queryStrings.redirectTo);
  if(!domain.endsWith('govinvest.com') && !domain.endsWith('localhost')) {
    console.error('Bad redirectTo:' + domain);
    errorRedirect('bad_redirect', 'Not a valid redirect')
    return <div></div>;
  }
  const disableCancel = queryStrings.disableCancel === 'true';
  const handleOnClose = () => {
    window.location.replace(queryStrings.redirectTo);
  };
  return (
    <div style={{ margin: '30px', marginTop: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div><img style={{ height: '50px', marginBottom: '20px' }} src="/govinvest_logo_no_subtitle_small.png" alt="GovInvest Logo" /></div>
      <ChangePasswordDialog open={true} onClose={handleOnClose} disableCancel={disableCancel} />
    </div>
  );
}

export default ChangePasswordPage;