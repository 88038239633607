import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../shared/components/Button';
import { postCommand } from '../../../shared/commandActions';
import { getTenants } from '../tenantsList/tenantsListActions';
import { validateName } from '../../../lib/validators';
import { fontSizes } from '../../../styles/vars';
import CustomInput from 'shared/components/CustomInput';

function EditTenantDiialog(props) {
  const dispatch = useDispatch();
  const { tenantId, open, onClose, currentName } = props;
  const [name, setName] = useState(currentName);
  const tenants = useSelector(rs => rs.tenantsList.tenants);
  const [saving, setSaving] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAddClicked = async () => {
    setSaving(true);
    const cmd = {
      type: 'UPDATE_TENANT',
      payload: {
        id: tenantId,
        name: name,
      },
    };
    const result = await postCommand(cmd);
    setSaving(false);
    if(result.error) {
      console.error(result);
    } else {
      dispatch(getTenants());
      onClose();
    }
  };

  const handleCancelClicked = () => {
    onClose();
  };

  let nameValid = validateName(name);
  if(tenants.filter(x => x.id !== tenantId).find(x => x.name === name)) {
    nameValid = false;
  }

  var dirty = false;
  if(name !== currentName) {
    dirty = true;
  };

  const enableSave = dirty && nameValid && !saving;

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px' }}>
        <div style={{ padding: '10px', fontSize: fontSizes.l, marginRight: '200px' }}>Rename Tenant</div>
        <div style={{ padding: '30px 15px' }}>
          <CustomInput
            value={name}
            label='Tenant Name'
            error={!nameValid}
            onChange={handleNameChange}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium' }}>
          <Button primary disabled={!enableSave} style={{padding: '15px', margin: '5px'}} onClick={handleAddClicked}>Rename</Button>
          <Button style={{padding: '15px', margin: '5px'}} onClick={handleCancelClicked}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default EditTenantDiialog;