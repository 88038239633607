import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import TenantsList from '../tenants/tenantsList/TenantsList';
import TenantDetail from '../tenants/tenantDetail/TenantDetail';
import UsersList from '../usersList/UsersList';
import GroupsList from '../groupsList/GroupsList';
import UserEventLog from 'components/userEventLog/UserEventLog';
import UserEventLogDownload from 'components/userEventLogDownload/UserEventLogDownload';
import AppInfo from 'components/appInfo/AppInfo';
import TraceLog from 'components/traceLog/TraceLog';
import SecurityEventLog from 'components/securityEventLog/SecurityEventLog';
import UserLanding from 'components/userLanding/UserLanding';

function MainContent() {
  return (
    <div style={{padding:'10px'}}>
      <Switch>
        <Route exact path="/app">
          <Redirect to="/app/tenants" />
        </Route>
        <Route exact path="/app/tenants">
          <TenantsList />
        </Route>
        <Route path="/app/tenants/:tenantId">
          <TenantDetail />
        </Route>
        <Route exact path="/app/users">
          <UsersList />
        </Route>
        <Route exact path="/app/groups">
          <GroupsList />
        </Route>
        <Route exact path="/app/usereventlog">
          <UserEventLog />
        </Route>
        <Route exact path="/app/usereventlogdownloads">
          <UserEventLogDownload />
        </Route>
        <Route exact path="/app/tracelog">
          <TraceLog />
        </Route>
        <Route exact path="/app/appinfo">
          <AppInfo />
        </Route>
        <Route exact path="/app/securityeventlog">
          <SecurityEventLog />
        </Route>
        <Route exact path="/app/userlanding">
          <UserLanding />
        </Route>
      </Switch>
    </div>
  );
}

export default MainContent;