export const filterSearch = (obj, filterString, searchKeys) => {
  if(!filterString || filterString.length <= 2)
    return true;
  const lowerCaseFilterString = filterString.toLowerCase();
  for(let k of searchKeys) {
    if(!!obj[k])
      if(obj[k].toString().toLowerCase().indexOf(lowerCaseFilterString) !== -1)
        return true;
  }
  return false;
};