import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CustomInput from './CustomInput';

function SearchBox(props) {
  const { onChange } = props;
  return (
    <div>
      <CustomInput
        onChange={onChange}
        placeholder='Search'
        Icon={<SearchIcon />}
      />
    </div>
  );
}

export default SearchBox;
