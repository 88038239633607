import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  transform: ${props => (props.$small ? 'scale(0.8)' : '')};
  padding: ${props => (props.$small ? '5px' : '')};
`;

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: 'var(--color-theme-primary)',
    },
    '&$disabled': {
      color: 'var(--color-grey)',
    },
  },
  checked: {},
  disabled: {},
})(props => <StyledCheckbox color="default" {...props} />);

export default CustomCheckbox;
