import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import SearchBox from 'shared/components/SearchBox';
import { fontSizes } from 'styles/vars'
import { sortAlphabeticallyByNumeric as sortNumeric } from 'lib/util';
import { formatUnixTimeSeconds } from 'lib/util';
import Spinner from 'shared/components/spinner';
import { getUsers } from 'components/usersList/usersListActions';
import { filterSearch } from 'lib/filterUtils';

function SecurityEventLog() {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState('');
  const [events, setEvents] = useState(null);
  const users = useSelector(x => x.usersList.users);

  useEffect(() => {
    dispatch(getUsers());
    fetchLog();
  }, [dispatch]);


  const fetchLog = async () => {
    const result = await fetch(`/api/securityeventlog`, {
        method: 'GET',
    });
    if(result.status !== 200) {
        console.log('error fetching securityeventlog:', result.status);
        return;
    }
    let items = [];
    try {
        items = await result.json();
        setEvents(items);
    }
    catch(e) {
        console.error(e);
        return;
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchFilter(value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const eventInFilter = (evt) => {
    //this is missing email, but it's OK as it's a sysadmin only page
    return filterSearch(evt, searchFilter, ['type', 'userId', 'ipAddress', 'clientId', 'role']);
  };

  const emailFromUserId = (userId) => {
    const u = users.find(x => x.id === userId);
    if(!u)
      return '';
    return u.email;
  };

  if(!events || !users)
    return <Spinner/>

  const filteredEvents = !!events && events.filter(x => eventInFilter(x));
  const sortedFiltered = !!filteredEvents && sortNumeric(filteredEvents, 'unixTime').reverse();
  const noFilteredEvents = events && events.length > 0 && sortedFiltered.length === 0;

  return (
    <>
      <div style={{padding: '15px', display:'flex', alignItems:'center'}}>
        <div style={{fontSize:fontSizes.l, marginRight:'30px'}}>
          Security Event Log
        </div>

        <div style={{display:'flex'}}>
          <div style={{padding:'3px'}}>
            <SearchBox onChange={debouncedHandleSearchChange} />
          </div>
        </div>

      </div>
      <div style={{padding: '15px 0px 15px 15px', fontSize: fontSizes.r}}>
        {
            <div className="table zebra dense" style={{ fontSize: 'smaller' }}>
              <div className="table-row" style={{ fontWeight: 'bold' }}>
                <div className="col-2">Time</div>
                <div className="col-3">Email</div>
                <div className="col-2">Type</div>
                <div className="col-2">IP Address</div>
                <div className="col-2">UserId</div>
                <div className="col-1">Role</div>
              </div>
              {
                noFilteredEvents && <div style={{padding:'10px'}}>No events found</div>
              }
              {
                (sortedFiltered &&  sortedFiltered.length) > 0 && sortedFiltered.map(evt => {
                  return (
                    <div className="table-row" key={evt.id}>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{formatUnixTimeSeconds(evt.unixTime/1000)}</div>
                      <div className="col-3" style={{height: '100%', display:'flex', alignItems:'center'}}>{emailFromUserId(evt.userId)}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.type}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.ipAddress}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.userId}</div>
                      <div className="col-1" style={{height: '100%', display:'flex', alignItems:'center'}}>{evt.role}</div>
                    </div>
                  )
                })
              }
            </div>
        }
      </div>
    </>
  )
}

export default SecurityEventLog;