import _ from 'lodash';

const initialState = {
  appEnvironments: null,
  currentTenant: null,
  error: null,
};

export const shared = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_APP_ENVIRONMENTS_SUCCESS': {
      const newState = _.cloneDeep(state);
      newState.appEnvironments = _.cloneDeep(action.payload.appEnvironments);
      return newState;
    }
    case 'GET_TENANT_SUCCESS': {
      const newState = _.cloneDeep(state);
      newState.currentTenant = _.cloneDeep(action.payload.tenant);
      return newState;
    }
    case 'ERROR_OCCURRED': {
      const newState = _.cloneDeep(state);
      newState.error = action.payload;
      return newState;
    }
    case 'CLEAR_ERROR': {
      const newState = _.cloneDeep(state);
      newState.error = null;
      return newState;
    }
    default:
      return state;
  }
};