import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HeaderProfile from '../headerProfile/HeaderProfile';
import SysAdminMenu from '../sysAdminMenu/SysAdminMenu';
import * as constants from '../../constants';
import HomeButton from './HomeButton';

function Header() {
  const profile = useSelector(rs => rs.currentUser.profile);
  const tenants = useSelector(rs => rs.tenantsList.tenants);
  const showTenantsButton = tenants && tenants.length > 1;
  const showUsersButton = constants.tenantAdminRoles.indexOf(profile.role) !== -1;
  const showGroupsButton = showUsersButton;
  const showUserEventsMenu = showUsersButton;
  const isSysAdmin = profile.role === constants.roles.SysAdmin;
  const itemStyle = {
    padding:'3px',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
  };
  return (
    <>
    <div style={{marginLeft: '5px', marginRight: '5px', padding:'5px'}} className="header-container">
      <div className="logo-title-container">
        <div style={itemStyle}>
          <img style={{height:'40px'}} src="/govinvest_logo_no_subtitle_small.png" alt="GovInvest, Inc. Logo"/>
        </div>
        <div style={itemStyle}>
          <div style={{fontWeight:1.2, fontSize:"24px", marginLeft: '10px'}}>User Management</div>
        </div>
      </div>
      <div style={{display:'flex'}}>
        {
          showTenantsButton && (
            <div style={{...itemStyle, marginRight: '20px', fontSize:'small'}}>
              <Link to="/app/tenants">Tenants</Link>
            </div>
          )
        }
        {
          showUsersButton && (
          <div style={{...itemStyle, marginRight: '20px', fontSize:'small'}}>
            <Link to="/app/users">Users</Link>
          </div>
          )
        }
        {
          showGroupsButton && (
          <div style={{...itemStyle, marginRight: '20px', fontSize:'small'}}>
            <Link to="/app/groups">Groups</Link>
          </div>
          )
        }
        {
          showUserEventsMenu && (
          <div style={{...itemStyle, marginRight: '20px', fontSize:'small'}}>
            <Link to="/app/usereventlog">User Log</Link>
          </div>
          )
        }
        {
          isSysAdmin &&
          <div style={{...itemStyle, marginRight: '5px'}}>
            <SysAdminMenu />
          </div>
        }
        <div style={itemStyle}>
          <HomeButton />
        </div>
        <div style={itemStyle}>
          <HeaderProfile />
        </div>
      </div>
    </div>
    </>
  );
}

export default Header;