export const roles = {
  SysAdmin: 'SysAdmin',
  Operator: 'Operator',
  TenantAdmin: 'TenantAdmin',
  User: 'User',
  CorpAdmin: 'CorpAdmin',
};
export const tenantAdminRoles = [
  roles.SysAdmin, roles.CorpAdmin, roles.Operator
];
export const roleNamesById = {
  SysAdmin: 'Sys Admin',
  Operator: 'Operator',
  TenantAdmin: 'Tenant Admin',
  User: 'User',
  CorpAdmin: 'Corp Admin',
};
export const guestRoles = [ roles.User, roles.TenantAdmin, ];
export const availableRolesForAssignment = {
  SysAdmin: [roles.User, roles.TenantAdmin, roles.Operator, roles.SysAdmin, roles.CorpAdmin],
  CorpAdmin: [roles.User, roles.TenantAdmin, roles.Operator],
  Operator: [roles.User, roles.TenantAdmin],
  TenantAdmin: [roles.User, roles.TenantAdmin],
};
export const connectionTypes = {
  UserDatabase: 'UserDatabase',
};
export const modules = {
  Labor: 'Labor',
  Prometheus: 'Prometheus',
};
export const groupMembershipTypes = {
  User: 'User',
  Tenant: 'Tenant',
};