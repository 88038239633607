import { homeRedirect } from 'shared/redirects';
import { errorOccurred } from 'shared/sharedActions';

export const getUsers = () => async dispatch => {
    const result = await fetch(`/api/users`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Users', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
        dispatch({ type: 'GET_USERS_SUCCESS', payload: { users: items } });
    }
    catch (e) {
        console.error(e);
        dispatch(errorOccurred('Error Getting Users', 'Unable to read response'));
    }
};
