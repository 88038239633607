import _ from 'lodash';

const initialState = {
  tenants: null,
};

export const tenantsList = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TENANTS_SUCCESS':
      const newState = _.cloneDeep(state);
      newState.tenants = _.cloneDeep(action.payload.tenants);
      return newState;
    default:
      return state;
  }
};