import React, { useEffect, useState } from 'react';
import urlJoin from 'url-join';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { fontSizes } from 'styles/vars'

function UserEventLogDownload() {
  const [downloadDateTimes, setDownloadDateTimes] = useState([]);

  useEffect(() => {
    setDownloadDateTimes(buildDownloadDateTimes());
  }, []);

  const getMonthKey = (dt) => {
    const month = dt.month.toString().padStart(2, '0');
    const year = dt.year.toString();
    const fmt = year + month;
    return fmt;
  };

  const buildDownloadDateTimes = () => {
    const stopTime = DateTime.now();
    let currentTime = DateTime.fromObject({ year:2021, month:9 });
    const ret = [];
    let cnt = 0;
    while(currentTime < stopTime) {
      ret.push(currentTime);
      currentTime = currentTime.plus( {month: 1} );
      if(cnt++ > 5000) //catch runaway loop
        break;
    }
    return ret.reverse();
  };

  const download = async (fmt) => {
    const config = window.env;
    const baseUrl = config.baseUrl;
    const url = urlJoin(baseUrl, '/api/usereventlogdownload?monthKey=' + fmt)
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  const tableItems = downloadDateTimes;

  return (
    <>
      <div style={{padding: '15px', display:'flex'}}>
        <div style={{fontSize:fontSizes.l, marginRight:'30px'}}>
          User Event Log Downloads
        </div>
      </div>
      <div style={{padding: '15px 0px 15px 15px', fontSize: fontSizes.r}}>
        {
            <div className="row table" style={{ fontSize: 'smaller', width:'600px' }}>
              <div className="table-row" style={{ fontWeight: 'bold' }}>
                <div className="col-12">Download</div>
              </div>
              {
                (!tableItems || tableItems.length === 0) && <div className="col-12" style={{height:'50px', display:'flex', alignItems:'center'}}>No events</div>
              }
              {
                (tableItems &&  tableItems.length) > 0 && tableItems.map(item => {
                  const monthKey = getMonthKey(item);
                  const formatted = item.toLocaleString({ year: 'numeric', month: 'short' })
                  return (
                      <div className="table-row" style={{height:'50px'}} key={monthKey}>
                        <Link to="#" onClick={() => download(monthKey)}>
                          <div className="col-12" style={{height: '100%', display:'flex', alignItems:'center'}}>{formatted} User Events Csv</div>
                        </Link>
                      </div>
                  )
                })
              }
            </div>
        }
      </div>
    </>
  )
}

export default UserEventLogDownload;