import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from './Button';
import { fontSizes } from '../../styles/vars';
import { convertErrorToTitle } from '../../lib/util';

function ErrorDialog(props) {
  const { open, onClose, dialogState } = props;
  const { error, errorDescription } = dialogState;

  const handleOnClose = (e, r) => {
    if(r === 'backdropClick') {
      return;
    }
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <div style={{ padding: '15px', fontSize: fontSizes.r, width:'550px' }}>
        <div style={{ padding: '10px', fontSize: fontSizes.l }}>An Error Has Occurred</div>

        <div style={{ display: 'flex', flexDirection:'column', fontSize: fontSizes.r, padding: '5px', marginBottom:'5px' }}>
          <div style={{ padding: '5px', lineHeight:'1.4em', marginBottom:'8px' }}><label style={{fontWeight:'bold'}}>Error:</label> {convertErrorToTitle(error)}</div>
          <div style={{ padding: '5px', lineHeight:'1.4em', marginBottom: '8px' }}><label style={{fontWeight:'bold'}}>Description:</label> {errorDescription}</div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: fontSizes.m }}>
          <Button danger style={{padding: '5px', margin: '5px'}} onClick={() => onClose()}>OK</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ErrorDialog;