import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { getTenants } from 'components/tenants/tenantsList/tenantsListActions';
import { getConnections } from 'components/connections/connectionActions';
import { getUsers } from './usersListActions.js';
import * as routeBuilder from '../../lib/routeBuilder.js';
import SearchBox from 'shared/components/SearchBox';
import { fontSizes } from 'styles/vars'
import { sortAlphabeticallyByField } from 'lib/util';
import { roleNamesById } from '../../constants.js';
import Spinner from 'shared/components/spinner';

function UsersList() {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState('');
  const users = useSelector(rs => rs.usersList.users);
  const connections = useSelector(rs => rs.connections.connections);
  const tenants = useSelector(rs => rs.tenantsList.tenants);

  useEffect(() => {
    dispatch(getTenants());
    dispatch(getUsers());
    dispatch(getConnections());
  }, [dispatch]);

  if(users == null || tenants == null || connections == null) {
    return <Spinner />;
  }

  const connectionNameById = (connectionId) => {
    const connection = connections.find(x => x.id === connectionId);
    if (!connection) {
      console.error('connection not found:' + connectionId);
      return 'ERROR';
    }
    return connection.name;
  };

  const tenantNameById = (tenantId) => {
    const tenant = tenants.find(x => x.id === tenantId);
    if (!tenant) {
      console.error('tenant not found:' + tenantId);
      return 'ERROR';
    }
    return tenant.name;
  }

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchFilter(value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);

  const userInFilter = (user) => {
    const test = (str) => {
      return str.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1;
    };

    if(test(user.name)) { return true; }
    if(test(user.email)) { return true; }
    if(test(user.role)) { return true; }
    if(test(roleNamesById[user.role])) { return true; }
    const tenantName = tenantNameById(user.tenantId);
    if(test(tenantName)) { return true; }

    return false;
  };

  const filteredUsers = !!users ? users.filter(x => userInFilter(x)) : null;
  const sortedFilteredUsers = !!filteredUsers ? sortAlphabeticallyByField(filteredUsers, 'name') : null;

  return (
    <>
      <div style={{padding: '15px', display:'flex', alignItems:'center'}}>
        <div style={{fontSize:fontSizes.l, marginRight:'30px'}}>
          Users
        </div>

        <div style={{display:'flex'}}>
          <div style={{padding:'3px'}}>
            <SearchBox onChange={debouncedHandleSearchChange} />
          </div>
        </div>

      </div>
      <div style={{padding: '15px 0px 15px 15px', fontSize: fontSizes.r}}>
        {
            <div className="table zebra" style={{ fontSize: 'smaller' }}>
              <div className="table-row" style={{ fontWeight: 'bold' }}>
                <div className="col-2">Name</div>
                <div className="col-2">E-mail</div>
                <div className="col-2">Title</div>
                <div className="col-2">Phone</div>
                <div className="col-2">Tenant</div>
                <div className="col-1">Role</div>
                <div className="col-2">Connection</div>
              </div>
              {
                (users && users.length === 0) && <Spinner />
              }
              {
                sortedFilteredUsers.map(user => {
                  const name = user.name === '__no_name__' ? '(No name)' : user.name;
                  return (
                    <div className="table-row" key={user.id}>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{name}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{user.email}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{user.title}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{user.phone}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>
                        <Link to={routeBuilder.buildTenantDetail(user.tenantId)}>{tenantNameById(user.tenantId)}</Link>
                      </div>
                      <div className="col-1" style={{height: '100%', display:'flex', alignItems:'center'}}>{roleNamesById[user.role]}</div>
                      <div className="col-2" style={{height: '100%', display:'flex', alignItems:'center'}}>{connectionNameById(user.connectionId)}</div>
                    </div>
                  )
                })
              }
            </div>
        }
      </div>
    </>
  )
}

export default UsersList;