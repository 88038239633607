import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '@fortawesome/fontawesome-free-solid';
import './headerProfile.css';
import Auth from '../../shared/auth';
import { colors } from '../../styles/colors';
import ChangePasswordDialog from './ChangePasswordDialog';
import TenantImage from '../../shared/components/tenantImage/TenantImage';
import * as constants from '../../constants';

function HeaderProfile() {
  const [changePasswordRequested, setChangePasswordRequested] = useState(false);
  const profile = useSelector(rs => rs.currentUser.profile);
  let name = profile ? profile.name : '';
  if(name === '__no_name__')
    name = profile.email;
  let roleText = '';
  if(profile && !!profile.role) {
    if(profile.role !== constants.roles.User) {
      roleText = `(${constants.roleNamesById[profile.role]})`;
    }
  }
  
  const showChangePassword = changePasswordRequested || profile.requiresPasswordReset;

  const auth = new Auth();
  return (
    <>
    <div className="dropdown" style={{cursor:'pointer'}}>
      <div style={{display: 'flex'}}>
        <div style={{padding: '5px', fontSize:'small', display:'flex', alignItems:'center'}}>
          {name} {roleText}
        </div>
        <div style={{padding: '5px', fontSize:'small', color: colors.green}}>
          <TenantImage tenantId={profile.tenantId} width="32px" />
        </div>
      </div>
      <div className="dropdown-content">
        { profile.userDatabaseConnection && <div onClick={() => setChangePasswordRequested(true)}>Change Password</div> }
        { profile.userDatabaseConnection && ( <div onClick={() => auth.logout()}>Logout</div>)}
        { !profile.userDatabaseConnection && ( 
          <>
            <div onClick={() => auth.switchUser()}>Logout</div>
            { !profile.ssoLogoutDisabled && <div onClick={() => auth.logout()}>Logout SSO</div> }
          </>
        )}
      </div>
    </div>
    { showChangePassword && <ChangePasswordDialog open={showChangePassword} onClose={() => setChangePasswordRequested(false)} />}
    </>
  );
}

export default HeaderProfile;