import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { Provider } from 'react-redux';
import history from './lib/history.js';
import store from './storeSetup';
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from './components/login/Login';
import LoginFailed from './components/loginFailed/LoginFailed';
import DeviceLoginCallback from './components/deviceLoginCallback/DeviceLoginCallback';
import DeviceLogin from './components/deviceLogin/DeviceLogin';
import CustomError from './components/customError/CustomError';
import Auth from './shared/auth';
import AuthManager from './shared/authManager';
import NotFound from './components/notFound/NotFound';
import App from './App';
import Spinner from 'shared/components/spinner';

function Routes() {
  const [authManagerStartInfo, setAuthManagerStartInfo] = useState(null);
  const [authStartInfo, setAuthStartInfo] = useState(null);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    const getAuthStartInfo = async () => {
      if(window.location.pathname.startsWith('/auth')) {
        const startInfo = await new AuthManager().handleStart();    //Middleware login functions
        setAuthManagerStartInfo(startInfo);
      } else {
        const si = await new Auth().handleStart(); //IUM Web client auth
        setAuthStartInfo(si);
      }
      setStarted(true);
    };
    if (!started) {
      getAuthStartInfo();
    }
  }, [started]);

  const loading = <Spinner />;

  if(window.location.pathname === '/error') {
    const queryStrings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    return <CustomError error={queryStrings.error} errorDescription={queryStrings.error_description} />
  }

  if(window.location.pathname === '/callback') {
    if(!authStartInfo) {
      return loading;
    }
    if(authStartInfo.error) {
      return <LoginFailed error={authStartInfo.error} errorDescription={authStartInfo.errorDescription} />
    }
  }

  if(authManagerStartInfo) {
    if(!authManagerStartInfo.mode) {
      return loading;
    }

    if (authManagerStartInfo.mode === 'device_login') {
      return (<DeviceLogin />);
    }

    if (authManagerStartInfo.mode === 'device_callback') {
      return (<DeviceLoginCallback />);
    }

    if (authManagerStartInfo.mode === 'already_logged_in') {
      return (<Spinner />);
    }

    if (authManagerStartInfo.mode === 'error') {
      return (<CustomError error={authManagerStartInfo.error} errorDescription={authManagerStartInfo.errorDescription}  />);
    }

    if (authManagerStartInfo.mode === 'app_login') {
      return (<Login authStatus={authManagerStartInfo.authStatus} error={authManagerStartInfo.error} errorDescription={authManagerStartInfo.errorDescription} />);
    }

    return (<CustomError error="Invalid mode" errorDescription="Invalid mode" />);
  }

  if (authStartInfo && authStartInfo.mode === 'error') {
    return (<CustomError error={authStartInfo.error} errorDescription={authStartInfo.errorDescription}  />);
  }

  if(!started) {
    console.log('waiting to start')
    return loading;
  }

  console.log('started')

  const auth = new Auth();
  if(!auth.isLoggedIn()) {
    auth.initiateLogin();
    return loading;
  }

  return (
    <Provider store={store}>
      <React.Fragment>
        <Router history={history} component={App}>
          <Switch>
            <Route exact path="/callback" >
              <Redirect to="/app/tenants" />
            </Route>
            <Route exact path="/" >
              <Redirect to="/app/tenants" />
            </Route>
            <Route exact path="/app" >
              <Redirect to="/app/tenants" />
            </Route>
            <Route path="/app/*">
              <App />
            </Route>
            <Route path="*" component={NotFound} status={404} />
          </Switch>
        </Router>
      </React.Fragment>
    </Provider>
  );
}

export default Routes;