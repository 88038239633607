import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../shared/components/Button';
import AlertDialog from '../../../shared/components/AlertDialog';
import ErrorDialog from '../../../shared/components/ErrorDialog';
import { postCommand } from '../../../shared/commandActions';
import { fontSizes } from '../../../styles/vars'

function UpdateImageDialog(props) {
  const { open, onClose, tenantId } = props;
  const [image, setImage] = useState(null)
  const [data, setData] = useState(null)
  const [alertState, setAlertState] = useState({ visible: false, title: '', message: '' });
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });

  const handleUpdateClicked = async () => {
    const cmd = {
      type: 'UPDATE_TENANT_IMAGE',
      payload: {
        tenantId: tenantId,
        imageBase64: data,
      },
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setErrorDialogState({ visible: true, error: result.error, errorDescription: result.errorDescription });
    } else {
      setAlertState({visible: true, title: 'Image Updated', message: 'Image update successful. Refresh your browser to see the new image.' })
    }
  };

  const handleCancelClicked = () => {
    onClose();
  };

  const handleAlertClosed = () => {
    setAlertState({ visible: false });
    onClose();
  };

  const handleFileSelected = async (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => setData(reader.result);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px', width: '400px' }}>
        <div style={{ padding: '10px', fontSize: fontSizes.l }}>Update Image</div>
        <div style={{ padding: '10px', paddingBottom:'5px', fontSize: fontSizes.r }}>For best results, use a square image. It will be compressed to 40x40 pixels.</div>

        <div className="row" style={{ padding: '25px', display: 'flex', alignItems: 'center' }}>

          <input type="file" onChange={handleFileSelected} accept="image/png, image/jpeg" />
          {
            !!image && <img src={image} style={{ height: '40px' }} alt="Tenant Logo" />
          }

        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button primary onClick={handleUpdateClicked}>Update</Button>
          <Button onClick={handleCancelClicked}>Cancel</Button>
        </div>
      </div>
      {errorDialogState.visible && <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={() => setErrorDialogState({ ...errorDialogState, visible: false })} />}
      {alertState.visible && <AlertDialog alertState={alertState} open={alertState.visible} onClose={handleAlertClosed} />}
    </Dialog>
  );
}

export default UpdateImageDialog;