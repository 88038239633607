export const validateName = (value) => {
  return /(^[a-zA-Z0-9_\-,.()])([a-zA-Z0-9_\- ,'.()]{0,48})([a-zA-Z0-9_\-,.()])$/.test(value);
};

export const validateOptionalName = (value) => {
  if(!value)
    return true;
  return validateName(value);
};

export const validateEmail = (value) => {
  return /(^[a-zA-Z0-9_\-.]{1,100})(@)([a-zA-Z0-9_\-.]{1,100})\.([a-zA-Z0-9_-]{2,4})$/.test(value);
};
