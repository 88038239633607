import React, { useState } from 'react';
import govInvestLogoIcon from '../../assets/govinvest_logo_icon.png';
import govInvestLogoTitle from '../../assets/govinvest_logo_title.png';
import govInvestLogoSubtitle from '../../assets/govinvest_logo_subtitle.png';
import styled from 'styled-components';
import AuthManager from '../../shared/authManager';
import Button from '../../shared/components/Button';
import { fontSizes } from '../../styles/vars';
import { validateEmail } from '../../lib/validators';
import Spinner from 'shared/components/spinner';

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 12%;
`;

const LoginGrid = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  margin-bottom: 20vh;
`;

const LogoGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: center;
  width: 600px;
  margin-right: 170px;
`;

const LogoTitleContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  align-items: center;
  justify-items: center;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const LogoTitleStyled = styled(StyledImg)``;

const LogoSubtitleStyled = styled(StyledImg)`
  width: 350px;
`;

const StyledLogoIcon = styled(StyledImg)`
  width: 150px;
`;

const AppName = styled.h1`
  color: var(--color-grey-d);
  font-size: 2.8em;
  font-weight: 400;
  margin: 0 0 30px 0;
`;

function Login() {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [dbConnection, setDbConnection] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const auth = new AuthManager();

  const handleEmailChanged = (e) => {
    const newEmail = e.target.value;
    setEmail(e.target.value);
    const isValidEmail = validateEmail(newEmail);
    setIsValidEmail(isValidEmail);
    if(!isValidEmail) {
      setDbConnection(true);
      return;
    }

    const envConfig = window.env;
    const config = {
      clientId: envConfig.clientId,
      iumBaseUrl: envConfig.baseUrl,
    }

    const encodedEmail = encodeURI(e.target.value);
    const clientId = config.clientId;
    fetch(`/api/userconnections?email=${encodedEmail}&clientId=${clientId}`, { method: 'GET', })
      .then(r => {
        if(r.ok) {
          setDbConnection(false);
        }
      });
  }

  const handlePasswordChanged = (e) => {
    setPassword(e.target.value);
  };

  function handleLoginClick() {
    if(!email) {
      alert('Please provide an e-mail address.')
      return;
    }
    setShowSpinner(true);
    if(dbConnection) {
      auth.doDeviceDatabaseLogin(email, password);
    } else {
      auth.doDeviceSsoLogin(email);
    }
  }

  const handlePasswordOnKeyUp = (e) => {
    if(e.key === 'Enter') {
      setShowSpinner(true);
      handleLoginClick();
    }
  };

  const handleEmailOnKeyUp = (e) => {
    if(e.key === 'Enter' && !dbConnection) {
      setShowSpinner(true);
      auth.doDeviceSsoLogin(email);
    }
  };

  const appTitle = auth.getDeviceTitle();

  var showPassword = true;
  var message = 'Please enter your email address and password to continue.';

  if(!dbConnection) {
    showPassword = false;
    message = 'Press next to login with your enterprise credentials.'
  }

  var enableLoginButton = true;
  if(!isValidEmail) {
    enableLoginButton = false;
  }
  if(dbConnection && (!password || password.length === 0)) {
    enableLoginButton = false;
  }

  if(showSpinner)
    return <Spinner />

  return (

    <LoginContainer>
      <LoginGrid>
        <LogoGrid>
          <StyledLogoIcon 
            src={govInvestLogoIcon}
            alt={"govinvest logo icon"}
          />
          <LogoTitleContainer>
            <LogoTitleStyled
              src={govInvestLogoTitle}
              alt="govinvest"
            />
            <LogoSubtitleStyled
              src={govInvestLogoSubtitle}
              alt="insights to your financial future"
            />
          </LogoTitleContainer>
        </LogoGrid>
        <AppName>{appTitle} Device Login</AppName>
        <div style={{width: '300px', display:'flex', flexDirection:'column', alignItems:'left'}}>

          <div style={{fontSize:fontSizes.s, padding:'2px'}}>Email</div>
          <input style={{width:'300px', fontSize: fontSizes.m}} type="email" onChange={handleEmailChanged} onKeyUp={handleEmailOnKeyUp} placeholder="email" value={email}></input>

          { showPassword && (
            <>
              <div style={{fontSize:fontSizes.s, padding:'2px', marginTop: '5px'}}>Password</div>
              <input style={{width:'300px', fontSize: fontSizes.m}} type="password" onChange={handlePasswordChanged} onKeyUp={handlePasswordOnKeyUp} placeholder="password" value={password}></input>
            </>
          )}

          <div style={{textAlign:'left', fontStyle:'italic', fontSize:'small', marginBottom: '8px', marginTop:'5px'}}>{message}</div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse'}}>
            <Button disabled={!enableLoginButton} primary onClick={handleLoginClick}>
              Login
            </Button>
          </div>
        </div>
      </LoginGrid>
    </LoginContainer>
  );
};

export default Login;