import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './App.css';
import MainPage from './components/mainPage/MainPage';
import Auth from './shared/auth';
import { getConnections } from './components/connections/connectionActions';
import { tenantAdminRoles } from './constants';
import ChangePasswordPage from 'components/changePasswordPage/ChangePasswordPage';
import ErrorDialog from 'shared/components/ErrorDialog';
import { clearError } from 'shared/sharedActions';
import Spinner from 'shared/components/spinner';

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(rs => rs.currentUser.profile);
  const error = useSelector(rs => rs.shared.error);
  const authProfile = new Auth().getProfile();

  if(!profile && authProfile) {
    dispatch({ type: 'PROFILE_AVAILABLE', payload: authProfile});

    if(Object.values(tenantAdminRoles).indexOf(authProfile.role) !== -1) {
      dispatch(getConnections());
    }
  }

  useEffect(() => {
    if(profile) {
      const auth = new Auth();
      if(!auth.getRenewScheduled()) {
        auth.scheduleRenewalWithRandom();
      } else {
        console.log('Renew already scheduled')
      }
    }
  }, [profile]);

  useEffect(() => {
    if(profile) {
      const auth = new Auth();
      const requestedUrl = auth.getRequestedUrl();
      if(!!requestedUrl) {
        auth.clearRequestedUrl();
        if(requestedUrl.indexOf('logout_callback') === -1)
          history.push(requestedUrl);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  if(!authProfile) {
    return <Spinner />;
  }

  if(window.location.pathname === '/app/change_password') {
    return <ChangePasswordPage />
  }

  const showAlert = !!error;
  return (
    <>
      <MainPage />
      { error && <ErrorDialog open={showAlert} dialogState={error} onClose={() => dispatch(clearError())} /> }
    </>
  );
}

export default App;