import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from './Button';

function AlertDialog(props) {
  const { open, onClose, alertState } = props;
  if(!alertState)
    return <div></div>;

  const handleOnClose = (e, r) => {
    if(r === 'backdropClick') {
      return;
    }
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <div style={{ padding: '15px' }}>
        <div style={{ padding: '10px', fontSize: 'large', marginRight: '200px' }}>{alertState.title}</div>
        <div style={{ display: 'flex', fontSize: 'small', padding: '5px', marginBottom:'10px' }}>
          <div style={{ padding: '5px' }}>{alertState.message}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium' }}>
          <Button primary style={{padding: '5px', margin: '5px'}} onClick={() => onClose()}>OK</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default AlertDialog;