import _ from 'lodash';

const initialState = {
  membersInGroup: null,
  selectedGroupId: null,
  groups: null,
  selectedMemberId: null,
  selectedMemberGroupMemberships: null,
};

export const groupsList = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_GROUPS_SUCCESS': {
      const newState = _.cloneDeep(state);
      newState.groups = _.cloneDeep(action.payload.groups);
      return newState;
    }
    case 'GET_GROUP_MEMBERS_SUCCESS': {
      const newState = _.cloneDeep(state);
      newState.membersInGroup = _.cloneDeep(action.payload.members);
      newState.selectedGroupId = action.payload.groupId;
      return newState;
    }
    case 'GET_MEMBER_GROUPS_SUCCESS': {
      const newState = _.cloneDeep(state);
      newState.selectedMemberGroupMemberships = _.cloneDeep(action.payload.groups);
      newState.selectedMemberId = action.payload.memberId;
      return newState;
    }
    default:
      return state;
  }
};