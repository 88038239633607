import _ from 'lodash';

const initialState = {
  profile: null,
};

export const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case 'PROFILE_AVAILABLE':
      const payload = action.payload;
      const newState = {
        profile: _.cloneDeep(payload),
      };
      return newState;
    default:
      return state;
  }
};