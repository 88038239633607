export const colors = {
  primary: '#a1a9b3',
  secondary: '#4e4e4e',
  bg: '#373b4f',
  bgLight: '#3E4156',
  bgDark: '#1c252e',
  error: '#db3537',
  success: '#77ff7a',
  white: '#fff',
  black: '#000',
  lightGrey: '#f2f2f2',
  grey: '#9f9e9c',
  orange: '#e58d10',
  darkGrey: '#222222',
  red: '#e55e64',
  darkRed: '#a14d50',
  darkestRed: '#89383c',
  lightblue: '#82CCE2',
  blue: '#4db7d9',
  darkBlue: '#6f73e6',
  darkestBlue: '#2e6e82',
  green: '#34a853',
  darkGreen: '#24763a',
  darkestGreen: '#1f6532',
  yellow: '#ffbb00',
  darkYellow: '#cc9600',
  darkestYellow: '#997000',
  purple: '#954f9a',
  darkPurple: '#773f7b',
  darkestPurple: '#592f5c',
  groupBorder: 'hsl(213, 11%, 47%)',
  themePrimary: '#4cb887',
  themePrimaryLight: '#76c270',
};