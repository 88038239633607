import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { getTenantDetail } from '../tenantDetailActions';
import { fontSizes } from 'styles/vars';
import { colors } from 'styles/colors';
import SearchBox from 'shared/components/SearchBox';
import { postCommand } from 'shared/commandActions';
import AddUserDialog from './AddUserDialog';
import EditUserDialog from './EditUserDialog';
import GroupMembershipDialog from '../groupMembershipDialog/GroupMembershipDialog';
import IconLinkButton from 'shared/components/IconLinkButton';
import IconButton from 'shared/components/IconButton';
import ConfirmDialog from 'shared/components/ConfirmDialog';
import AlertDialog from 'shared/components/AlertDialog';
import ResetPasswordDialog from './ResetPasswordDialog';
import { sortAlphabeticallyByField } from 'lib/util';
import { roleNamesById } from 'constants.js';
import { arrayContains } from 'lib/util';
import { tenantAdminRoles } from 'constants.js';
import UserInfoDialog from './UserInfoDialog';

function UsersTable() {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showUserGroups, setShowUserGroups] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [userIdToReset, setUserIdToReset] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [userInfoUser, setUserInfoUser] = useState(null);
  const [confirmState, setConfirmState] = useState({ visible: false, title: '', message: '' });
  const [alertState, setAlertState] = useState({ visible: false, title: '', message: '' });
  const tenants = useSelector(x => x.tenantsList.tenants);
  const connections = useSelector(x => x.tenantDetail.connections);
  const currentUser = useSelector(x => x.currentUser.profile);
  const tenantDetail = useSelector(x => x.tenantDetail.detail);
  const prms = useParams();
  const tenantId = prms.tenantId;

  if (!tenants || !connections) {
    return <div></div>
  }
  const tenant = tenants.find(x => x.id === tenantId);
  const users = tenantDetail.users;

  const connectionNameById = (connectionId) => {
    const connection = connections.find(x => x.id === connectionId);
    if (!connection) {
      console.error('connection not found:' + connectionId);
      return 'ERROR';
    }
    return connection.name;
  };

  const handleSearchBoxChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const debouncedHandleSearchChange = debounce(handleSearchBoxChange, 300);

  const userInFilter = (user) => {
    if (user.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
      return true;
    }
    if (user.email.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
      return true;
    }
    if (user.role.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
      return true;
    }
    if (connectionNameById(user.connectionId).toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  };

  const handleResetPassword = (userId) => {
    setUserIdToReset(userId);
    setShowReset(true);
  };

  const handleCloseReset = () => {
    setUserIdToReset(null);
    setShowReset(false);
  };

  const handleDisableUser = async (user) => {
    if (currentUser.userId === user.id) {
      setAlertState({
        visible: true,
        title: 'Unauthorized',
        message: 'You cannot disable yourself.',
      });
      return;
    }
    setSaving(true);
    const cmd = { type: 'DISABLE_USER', payload: { id: user.id } };
    const result = await postCommand(cmd);
    if (result.error) {
      console.error(result);
    } else {
      dispatch(getTenantDetail(tenantId));
    }
    setSaving(false);
  };

  const handleEnableUser = async (user) => {
    setSaving(true);
    const cmd = { type: 'ENABLE_USER', payload: { id: user.id } };
    const result = await postCommand(cmd);
    if (result.error) {
      console.error(result);
    } else {
      dispatch(getTenantDetail(tenantId));
    }
    setSaving(false);
  };

  const handleDeleteUser = (userId) => {
    if (currentUser.userId === userId) {
      setAlertState({
        visible: true,
        title: 'Unauthorized',
        message: 'You cannot delete yourself.',
      });
      return;
    }

    const handleDeleteConfirmed = async (userId) => {
      const cmd = { type: 'DELETE_USER', payload: { tenantId: tenantId, userId: userId } };
      const result = await postCommand(cmd);
      if (result.error) {
        console.error(result);
      } else {
        dispatch(getTenantDetail(tenantId));
      }
    };
    setConfirmState({
      visible: true,
      title: 'Confirm Delete User',
      message: 'Are you sure you want to delete this user?',
      onConfirm: () => handleDeleteConfirmed(userId),
    });
  };

  const handleEditGroups = (user) => {
    setSelectedUser(user);
    setShowUserGroups(true);
  };

  const handleEditUser = (user) => {
    if (currentUser.userId === user.id) {
      setAlertState({
        visible: true,
        title: 'Unauthorized',
        message: 'You cannot edit yourself.',
      });
      return;
    }
    setEditUser(user);
    setShowEditUser(true);
  }

  const handleShowUserInfo = (user) => {
    setUserInfoUser(user);
    setShowUserInfo(true);
  }


  const getUserName = (user) => {
    if(user.name === '__no_name__') {
      return '(No name)';
    }
    return user.name;
  }

  const filteredUsers = !!users ? users.filter(x => userInFilter(x)) : null;
  const sortedFilteredUsers = !!filteredUsers ? sortAlphabeticallyByField(filteredUsers, 'name') : null;
  const showGroupsButton = arrayContains(tenantAdminRoles, currentUser.role);
  const readonly = tenantDetail.readonly;
  const disabled = saving;

  return (
    <div>
      <div style={{ display: 'flex', alignItems:'center', marginTop:'20px' }}>
        <div className="row">
          <div style={{ fontSize: fontSizes.m, padding: '15px', marginBottom:'5px' }}>Users</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px', color: colors.green }}>
          { !readonly && <IconLinkButton text="Add" icon="plus" onClick={() => setShowAddUser(true)} /> }
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchBox onChange={debouncedHandleSearchChange} />
        </div>
      </div>

      {
        !!users && (

          <div className="table zebra" style={{ fontSize: 'smaller' }}>
            <div className="table-row" style={{ fontWeight: 'bold' }}>
              <div className="col-2">Name</div>
              <div className="col-3">E-mail</div>
              <div className="col-2">Role</div>
              <div className="col-2">Connection</div>
              <div className="col-3">Actions</div>
            </div>
            {
              (filteredUsers && filteredUsers.length === 0) &&
                <div className="table-row" style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                  <div className="col-12">No users</div>
                </div>
            }
            {
              sortedFilteredUsers.map(user => {
                const userDisabled = user.status === 'disabled';
                const fontStyle = userDisabled ? 'italic' : '';
                return (
                  <div className="table-row" style={{ height: '50px' }} key={user.id}>
                    <div className="col-2" style={{ height: '100%', display: 'flex', alignItems: 'center', fontStyle: fontStyle }}>
                      {getUserName(user)} 
                      { userDisabled && (
                        <div style={{marginLeft:'3px'}}>(disabled)</div>
                      )}
                    </div>
                    <div className="col-3" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>{user.email}</div>
                    <div className="col-2" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>{roleNamesById[user.role]}</div>
                    <div className="col-2" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>{connectionNameById(user.connectionId)}</div>
                    <div className="col-3" style={{ height: '100%', display: 'flex', alignItems: 'center', padding: '8px', fontSize: fontSizes.m }}>
                      <div style={{ marginLeft: '8px', marginRight: '8px' }} title="User info">
                        <IconButton icon="info-circle" size="sm" onClick={() => handleShowUserInfo(user)} />
                      </div>
                      <div style={{ marginRight: '8px' }} title="Edit the user">
                        { !readonly && <IconButton disabled={disabled} icon="edit" size="sm" onClick={() => handleEditUser(user)} /> }
                      </div>
                      {
                        user.userDatabaseConnection && (
                          <div style={{ marginRight: '8px' }} title="Reset the user's password">
                            { !readonly && <IconButton disabled={disabled} icon="key" size="sm" onClick={() => handleResetPassword(user.id)} /> }
                          </div>
                        )
                      }
                      {
                        showGroupsButton && (
                          <div style={{ marginRight: '8px' }} title="Manage user's group membership">
                            { !readonly && <IconButton disabled={disabled} icon="users" size="sm" onClick={() => handleEditGroups(user)} /> }
                          </div>
                        )
                      }
                      {
                        !userDisabled && (
                          <div style={{ marginRight: '8px' }} title="Disable the user">
                            { !readonly && <IconButton disabled={disabled} icon="user-slash" size="sm" onClick={async () => await handleDisableUser(user)} /> }
                          </div>
                        )
                      }
                      {
                        userDisabled && (
                          <div style={{ marginRight: '8px' }} title="Enable the user">
                            { !readonly && <IconButton disabled={disabled} icon="user" size="sm" onClick={async () => await handleEnableUser(user)} /> }
                          </div>
                        )
                      }
                      <div style={{ marginRight: '8px' }} title="Delete the user">
                        { !readonly && <IconButton disabled={disabled} icon="trash" size="sm" onClick={() => handleDeleteUser(user.id)} /> }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      }
      {showAddUser && <AddUserDialog tenant={tenant} tenantDetail={tenantDetail} open={showAddUser} onClose={() => setShowAddUser(false)} />}
      {showEditUser && <EditUserDialog user={editUser} tenant={tenant} tenantDetail={tenantDetail} open={showEditUser} onClose={() => setShowEditUser(false)} />}
      {confirmState.visible && <ConfirmDialog confirmState={confirmState} open={confirmState.visible} onConfirm={confirmState.onConfirm} onClose={() => setConfirmState({ ...confirmState, visible: false })} />}
      {alertState.visible && <AlertDialog alertState={alertState} open={alertState.visible} onClose={() => setAlertState({ ...alertState, visible: false })} />}
      {showReset && <ResetPasswordDialog userId={userIdToReset} open={showReset} onClose={handleCloseReset} />}
      {showUserGroups && <GroupMembershipDialog user={selectedUser} open={showUserGroups} onClose={() => setShowUserGroups(false)} />}
      {showUserInfo && <UserInfoDialog user={userInfoUser} open={showUserInfo} onClose={() => setShowUserInfo(false)} />}
    </div>

  );
}

export default UsersTable;