import { homeRedirect } from 'shared/redirects';
import { errorOccurred } from 'shared/sharedActions';

export const getMembersForGroup = (groupId) => async dispatch => {
    const result = await fetch(`/api/groupmembership?groupId=${groupId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Group Members', 'Unexpected status code: ' + result.status));
        return;
    }

    let items = [];
    try {
        items = await result.json();
    }
    catch (e) {
        dispatch(errorOccurred('Error Getting Group Members', 'Unable to read response'));
        return;
    }

    const success = { type: 'GET_GROUP_MEMBERS_SUCCESS', payload: { groupId: groupId, members: items } };
    dispatch(success);
};

export const getGroups = () => async dispatch => {
    const result = await fetch(`/api/groups`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Groups', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
    }
    catch (e) {
        dispatch(errorOccurred('Error Getting Groups', 'Unable to read response'));
        return;
    }

    const success = { type: 'GET_GROUPS_SUCCESS', payload: { groups: items } };
    dispatch(success);
};

export const getGroupsForMember = (memberId) => async dispatch => {
    const result = await fetch(`/api/groupmembership?memberId=${memberId}`, {
        method: 'GET',
    });
    if(result.status === 401) {
        console.log('401 - redirecting home');
        homeRedirect();
        return;
    }
    if(result.status !== 200) {
        dispatch(errorOccurred('Error Getting Groups for Member', 'Unexpected status code: ' + result.status));
        return;
    }
    let items = [];
    try {
        items = await result.json();
    }
    catch (e) {
        dispatch(errorOccurred('Error Getting Groups for Member', 'Unable to read response'));
        return;
    }

    const success = { type: 'GET_MEMBER_GROUPS_SUCCESS', payload: { memberId: memberId, groups: items } };
    dispatch(success);
};