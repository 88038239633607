import React from 'react';

function DeviceLoginCallback(props) {
  return (
    <>
      <div style={{padding:'15px'}}>You have been logged in.  You can close this browser tab.</div>
    </>
  );
}

export default DeviceLoginCallback;