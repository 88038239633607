import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from 'shared/components/Button';
import ErrorDialog from 'shared/components/ErrorDialog';
import Checkbox from 'shared/components/Checkbox';
import { postCommand } from 'shared/commandActions';
import { getTenants } from '../tenantsList/tenantsListActions';
import { validateName } from 'lib/validators';
import { fontSizes } from 'styles/vars'
import { connectionTypes } from '../../../constants';
import CustomInput from 'shared/components/CustomInput';

function AddTenantDiialog(props) {
  const dispatch = useDispatch();
  const { open, onClose } = props;
  const [name, setName] = useState('');
  const tenants = useSelector(rs => rs.tenantsList.tenants);
  const connections = useSelector(rs => rs.connections.connections);
  const [connectionState, setConnectionState] = useState(null);
  const [saving, setSaving] = useState(false); 
  const [errorDialogState, setErrorDialogState] = useState({ visible: false });

  useEffect(() => {
  if(connectionState === null) {
    const newState = {};
    for(let c of connections) {
      newState[c.id] = c.connectionType === connectionTypes.UserDatabase;
    }
    setConnectionState(newState);
  }
  }, [connections, connectionState]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAddClicked = async () => {
    setSaving(true);
    const allowedConnectionIds = connections.filter(x => connectionState[x.id] === true).map(x => x.id);
    const cmd = {
      type: 'CREATE_TENANT',
      payload: {
        name: name,
        allowedConnectionIds: allowedConnectionIds,
      },
    };

    const result = await postCommand(cmd);
    setSaving(false);
    if(result.error) {
      setErrorDialogState({visible: true, title:'Error Adding Tenant', error: result.error, errorDescription: result.errorDescription });
    } else {
      dispatch(getTenants());
      setName('');
      onClose();
    }
  };

  const handleCancelClicked = () => {
    setName('');
    onClose();
  };

  const handleConnectionCheck = (e, connection) => {
    const newState = _.cloneDeep(connectionState);
    newState[connection.id] = e.target.checked;
    setConnectionState(newState);
  };

  let nameValid = validateName(name);

  if(tenants.find(x => x.name === name)) {
    nameValid = false;
  }

  if(name === '') {
    nameValid = true; //done to handle the starting case
  }

  if(!connectionState) {
    return <></>;
  }

  const validAllowedConnections = Object.values(connectionState).indexOf(true) !== -1;

  const enableSave = nameValid && name !== '' && validAllowedConnections && !saving;

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: '15px' }}>
        <div style={{ padding: '10px', fontSize: fontSizes.l, marginRight: '200px' }}>Add Tenant</div>

        <div style={{ padding: '15px' }}>
          <CustomInput
            value={name}
            label='Tenant Name'
            error={!nameValid}
            onChange={handleNameChange}
          />
        </div>

        <div style={{ display: 'flex', flexDirection:'column', fontSize: fontSizes.r, padding: '15px' }}>
          <div style={{ padding: '5px', fontSize:fontSizes.m }}>Allowed Connections</div>
          <div style={{padding: '5px', maxHeight:'250px', overflowY:'auto', border: '1px solid lightgrey'}}>
            {
              connections.map(c => (
                <div key={c.id} style={{display:'flex', flexDirection:'row', height:'40px'}}>
                  <Checkbox checked={connectionState[c.id]} onChange={(e) => handleConnectionCheck(e,c)} />
                  <div style={{height:'100%', display:'flex', alignItems:'center' }}>{c.name}</div>
                </div>
              ))
            }
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button primary disabled={!enableSave} onClick={handleAddClicked}>Add</Button>
          <Button  onClick={handleCancelClicked}>Cancel</Button>
        </div>
      </div>
      { errorDialogState.visible && <ErrorDialog dialogState={errorDialogState} open={errorDialogState.visible} onClose={() => setErrorDialogState({...errorDialogState, visible: false})} /> }
    </Dialog>
  );
}

export default AddTenantDiialog;